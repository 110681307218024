<template>
	<!--出票登记 - 待复核-->
	<div>
		<div class="btn-group">
			<el-button type="primary" @click="handleAgree">审核通过</el-button>
			<el-button type="warning" @click="rejectDialogVisible = true">审核驳回</el-button>
			<el-button @click="$router.go(-1)">返回</el-button>
		</div>
		<!--短信验证-->
		<el-dialog
						title="短信验证"
						:visible.sync="messageDialogVisible"
						width="50%"
						@close="messageDialogClosed">
			<el-form :model="messageForm"
			         :rules="messageFormRules"
			         ref="messageFormRef"
			         class="message-form">
				<el-form-item label="操作员手机号" prop="operatorMobile">
					{{mobile}}
				</el-form-item>
				<el-form-item label="短信验证码" prop="verifyCode">
					<el-input v-model="messageForm.verifyCode" class="verify-code"></el-input>
					<DelayButton :delay-time="60"/>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button type="primary" @click="handleSubmit">提交</el-button>
		    <el-button @click="messageDialogVisible = false">关闭</el-button>
		  </span>
		</el-dialog>
		<!--通过成功弹框-->
		<el-dialog :visible.sync="successDialogVisible"
		           width="40%"
		           :show-close="false"
		           :close-on-click-modal="false"
		           @close="$router.go(-1)">
			<div class="agree-dialog">
				<img src="~@/assets/img/ticket/cg.png" alt="">
				<div class="copy-code">
					操作编号：<h4>{{copyCode}}</h4>
				</div>
        <h3>{{copyText}}</h3>
				<el-button type="primary" @click="handleCopy">点击复制</el-button>
			</div>
			<span slot="footer" class="dialog-footer">
		    <el-button @click="successDialogVisible = false">关 闭</el-button>
		  </span>
		</el-dialog>
		<!--审核驳回-->
		<el-dialog
						title="驳回意见"
						:visible.sync="rejectDialogVisible"
						width="40%"
						@close="rejectDialogClosed">
			<el-form :model="rejectForm"
			         :rules="rejectFormRules"
			         ref="rejectFormRef"
			         class="message-form">
				<el-form-item prop="reject">
					<el-input v-model="rejectForm.reject" type="textarea" resize="none"></el-input>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
		    <el-button @click="rejectDialogVisible = false">关 闭</el-button>
		    <el-button type="primary" @click="handleRejectSubmit">确 定</el-button>
		  </span>
		</el-dialog>
	</div>
</template>

<script>
	import DelayButton from "@/views/Ticket/childrenCpn/DelayButton";
  export default {
    name: "OutBillReview",
    props: ['ticketId', 'handlerTypeCode', 'handlerNodeCode'],
	  components: {
      DelayButton
	  },
	  data(){
      return {
        mobile: '', //手机号
        copyCode: '', // 操作编号
        copyText: '', // 操作提示文字
        successDialogVisible: false, // 控制通过 | 驳回成功弹框显示与隐藏
        messageDialogVisible: false, // 控制短信弹框的显示与隐藏
        rejectDialogVisible: false, // 控制审核驳回弹框的显示与隐藏
        // 短信验证表单数据
        messageForm: {
          verifyCode: ''
        },
        // 短信验证表单验证规则
        messageFormRules:{
          verifyCode: [
            { required: true, message: '请输入验证码', trigger: 'blur' }
          ]
        },
        // 审核驳回表单数据
        rejectForm: {
          reject: ''// 驳回意见
        },
        // 审核驳回表单验证规则
        rejectFormRules: {
          reject: [
            { required: true, message: '请输入驳回意见', trigger: 'blur' }
          ]
        },
      }
	  },
	  methods: {
      async handleAgree(){
        const {data: res} = await this.$auth.get("/cpiaoju-ticket/common/mobile");
        if (res.code !== 200) return this.$message.error(res.msg);
        this.mobile = res.data.mobile;
        this.messageDialogVisible = true
      },
      // 审核通过|短信验证提交
      handleSubmit(){
				this.$refs.messageFormRef.validate(async valid => {
				  if (!valid) return
          // 短信验证参数
          let verifyParams = {
            ticketId: this.ticketId,
            handlerTypeCode: this.handlerTypeCode,
            handlerNodeCode: this.handlerNodeCode,
	          smsCode: this.messageForm.verifyCode
          };
          //确认提交参数
          let submitParams = {
            ticketId: this.ticketId,
            handlerTypeCode: this.handlerTypeCode,
            handlerNodeCode: this.handlerNodeCode
          }
          // 短信验证
          const {data: res} = await this.$auth.post("/cpiaoju-ticket/workbench/task/todo/verifyCode", this.$qs.stringify(verifyParams));
          if (res.code === 200) {
            //出票登记申请-待复核
            const {data: result} = await this.$auth.post("/cpiaoju-ticket/workbench/task/todo/mt/registerApply/recheck/pass", this.$qs.stringify(submitParams));
            this.copyText = result.data.text;
            this.copyCode = result.data.handlerNo;
            this.messageDialogVisible = false;
            this.successDialogVisible = true
          }else {
            this.$message.error(res.msg);
          }
				})
      },
      // 短信验证关闭
      messageDialogClosed(){
        this.$refs.messageFormRef.resetFields()
      },
		  // 审核驳回关闭
      rejectDialogClosed(){
        this.$refs.rejectFormRef.resetFields()
      },
		  // 审核驳回提交
      handleRejectSubmit(){
        this.$refs.rejectFormRef.validate(async valid => {
          if (!valid) return;
          let params = {
            ticketId: this.ticketId,
            handlerTypeCode: this.handlerTypeCode,
            handlerNodeCode: this.handlerNodeCode,
	          refuse: this.rejectForm.reject
          }
          const {data: res} = await this.$auth.post("/cpiaoju-ticket/workbench/task/todo/mt/registerApply/recheck/refuse", this.$qs.stringify(params));
          if(res.code !== 200) return this.$message.error(res.msg);
	        this.rejectDialogVisible = false
	        this.$router.go(-1)
        })
      },
      // 点击复制
      handleCopy(){
        this.$copyText(this.copyCode).then(res => {
          this.$message.success('复制成功')
          this.successDialogVisible = false
          return false;
        },err => {
          this.$message.error('复制失败')
          this.successDialogVisible = true
        })
      }
	  }
  }
</script>

<style scoped>

</style>