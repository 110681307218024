<template>
  <div>
    <el-descriptions :column="3">
      <el-descriptions-item label="操作类型">{{bizInfo.handlerType}}</el-descriptions-item>
      <el-descriptions-item label="操作编号">{{bizInfo.taskNo}}</el-descriptions-item>
      <el-descriptions-item label="任务状态">{{bizInfo.taskStatus}}</el-descriptions-item>
    </el-descriptions>
    <!--背书信息-->
    <el-descriptions title="背书信息" :column="2" class="mt30">
      <el-descriptions-item label="被背书人">{{baseInfo.compName}}</el-descriptions-item>
      <el-descriptions-item label="被背书人账号">{{baseInfo.acctNo}}</el-descriptions-item>
      <el-descriptions-item label="开户行">{{baseInfo.acctBankName}}</el-descriptions-item>
      <el-descriptions-item label="开户行行号">{{baseInfo.acctBankNo}}</el-descriptions-item>
      <el-descriptions-item label="背书金额">{{baseInfo.packageSum}}</el-descriptions-item>
      <el-descriptions-item label="申请日期">{{baseInfo.applyDate}}</el-descriptions-item>
      <el-descriptions-item label="转让标记">{{baseInfo.transferFlag}}</el-descriptions-item>
      <el-descriptions-item label="备注">{{baseInfo.remark===''? '无' : baseInfo.remark}}</el-descriptions-item>
    </el-descriptions>
    <WorkbenchPublicTitle :superInfoList="superInfoList">
      <template v-slot:titleText>
        <h3>母票基本信息</h3>
      </template>
    </WorkbenchPublicTitle>
    <el-table :data="superInfoList" border class="mt20">
      <el-table-column prop="packageNo" label="票据包号"></el-table-column>
      <el-table-column prop="packageSum" label="票据包金额"></el-table-column>
      <el-table-column prop="acceptor" label="承兑人"></el-table-column>
      <el-table-column prop="expireDate" label="票据到期日"></el-table-column>
      <el-table-column prop="ticketRange" label="子票区间"></el-table-column>
    </el-table>
    <template v-if="this.subInfoList !== null">
      <h3 class="mt30">子票基本信息</h3>
      <el-table :data="subInfoList" border class="mt20">
        <el-table-column prop="subTicketInfo" label="子票信息"></el-table-column>
        <el-table-column prop="possessor" label="票据归属"></el-table-column>
        <el-table-column prop="packageSum" label="子票包金额"></el-table-column>
        <el-table-column prop="transferFlag" label="转让标记"></el-table-column>
      </el-table>
    </template>
    <div class="btn-group">
      <el-button type="primary" @click="messageDialogVisible = true">审核通过</el-button>
      <el-button type="warning" @click="closeDialogVisible = true">关闭</el-button>
      <el-button @click="$router.go(-1)">返回</el-button>
    </div>
    <!--短信验证-->
    <el-dialog
            title="短信验证"
            :visible.sync="messageDialogVisible"
            :close-on-click-modal="false"
            width="40%"
            @close="messageDialogClosed">
      <el-form :model="messageForm"
               :rules="messageFormRules"
               ref="messageFormRef"
               class="message-form">
        <el-form-item label="操作员手机号" prop="operatorMobile">
          {{mobile}}
        </el-form-item>
        <el-form-item label="短信验证码" prop="verifyCode">
          <el-input v-model="messageForm.verifyCode" class="verify-code"></el-input>
          <DelayButton />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
				<el-button type="primary" @click="handleThroughSubmit">提交</el-button>
		    <el-button @click="messageDialogVisible = false">关闭</el-button>
		  </span>
    </el-dialog>
    <!--关闭弹框-->
    <el-dialog
            title="关闭意见"
            :visible.sync="closeDialogVisible"
            width="40%"
            @close="closeDialogClosed">
      <el-form :model="closeForm"
               :rules="closeFormRules"
               ref="closeFormRef"
               class="message-form">
        <el-form-item prop="reject">
          <el-input v-model="closeForm.reject" type="textarea" resize="none"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
		    <el-button @click="closeDialogVisible = false">关 闭</el-button>
		    <el-button type="primary" @click="handleCloseSubmit">确 定</el-button>
		  </span>
    </el-dialog>
    <!--成功弹框-->
    <el-dialog :visible.sync="successDialogVisible"
               width="40%"
               :show-close="false"
               :close-on-click-modal="false"
               @close="$router.go(-1)">
      <div class="agree-dialog">
        <img src="~@/assets/img/ticket/cg.png" alt="">
        <h3>{{copyText}}</h3>
        <div class="copy-code">
          操作编号：<h4>{{copyCode}}</h4>
        </div>
        <el-button type="primary" @click="handleCopy">点击复制</el-button>
      </div>
      <span slot="footer" class="dialog-footer">
		    <el-button @click="successDialogVisible = false">关 闭</el-button>
		  </span>
    </el-dialog>
  </div>
</template>

<script>
  /*倒计时按钮*/
  import DelayButton from "@/views/Ticket/childrenCpn/DelayButton";
  // 票面预览/票面下载
  import WorkbenchPublicTitle from "@/views/Ticket/routerCpn/workbench/publicCpn/WorkbenchPublicTitle";
  export default {
    name: "EndorseApply",
    props:['bizInfo', 'baseInfo', 'superInfoList', 'subInfoList'],
    components: {
      DelayButton,
      WorkbenchPublicTitle
    },
    data(){
      return {
        mobile: this.$store.state.getUser, //手机号
        copyCode: '', // 操作编号
        copyText: '', // 操作提示文字
        successDialogVisible: false, // 控制成功弹框的显示与隐藏
        messageDialogVisible: false, // 控制短信弹框的显示与隐藏
        // 短信验证表单数据
        messageForm: {
          verifyCode: ''
        },
        // 短信验证表单验证规则
        messageFormRules:{
          verifyCode: [
            { required: true, message: '请输入验证码', trigger: 'blur' }
          ]
        },
        closeDialogVisible: false, // 控制关闭弹框的显示与隐藏
        // 关闭意见表单数据
        closeForm: {
          reject: ''// 关闭意见
        },
        // 关闭意见表单验证规则
        closeFormRules: {
          reject: [
            { required: true, message: '请输入关闭意见', trigger: 'blur' }
          ]
        }
      }
    },
    methods: {
      // 短信验证关闭
      messageDialogClosed(){
        this.$refs.messageFormRef.resetFields()
      },
      // 审核通过提交
      handleThroughSubmit(){
        this.$refs.messageFormRef.validate(async valid => {
          if(!valid) return;
          // 短信验证参数
          let verifyParams = {
            ticketId: this.bizInfo.ticketId,
            handlerTypeCode: this.bizInfo.handlerTypeCode,
            handlerNodeCode: this.bizInfo.handlerNodeCode,
            smsCode: this.messageForm.verifyCode
          }
          // 审核通过参数
          let submitParams = {
            ticketId: this.bizInfo.ticketId,
            handlerTypeCode: this.bizInfo.handlerTypeCode,
            handlerNodeCode: this.bizInfo.handlerNodeCode,
          }
          const {data: res} = await this.$auth.post("/cpiaoju-ticket/workbench/task/todo/verifyCode", this.$qs.stringify(verifyParams));
          if (res.code === 200) {
            //提示承兑申请-待提示承兑申请-确认提交
            const {data: result} = await this.$auth.post("/cpiaoju-ticket/workbench/task/todo/bs/apply/recheck/pass", this.$qs.stringify(submitParams));
            this.copyText = result.data.text;
            this.copyCode = result.data.handlerNo;
            this.messageDialogVisible = false;
            this.successDialogVisible = true
          }else {
            this.$message.error(res.msg);
            this.messageDialogVisible = true
          }
        })
      },
      // 关闭弹框
      closeDialogClosed(){
        this.$refs.closeFormRef.resetFields()
      },
      // 关闭弹框的提交
      handleCloseSubmit(){
        this.$refs.closeFormRef.validate(async valid => {
          if (!valid) return;
          let params = {
            ticketId: this.bizInfo.ticketId,
            handlerTypeCode: this.bizInfo.handlerTypeCode,
            handlerNodeCode: this.bizInfo.handlerNodeCode,
            refuse: this.closeForm.reject
          }
          const {data: res} = await this.$auth.post('/cpiaoju-ticket/workbench/task/todo/bs/apply/recheck/close', this.$qs.stringify(params))
          if (res.code !== 200) return this.$message.error(res.msg)
          this.closeDialogVisible = false;
          this.$router.push('/ticket/taskWaitList')
        })
      },
      // 点击复制
      handleCopy(){
        this.$copyText(this.copyCode).then(res => {
          this.$message.success('复制成功')
          this.agreeDialogVisible = false
          return false;
        },err => {
          this.$message.error('复制失败')
          this.agreeDialogVisible = true
        })
      }
    }
  }
</script>

<style lang="less" scoped>
  .title{
    h3{
      display: inline-block;
      margin-right: 10px;
    }
  }
  /deep/ .btn-group{
    text-align: center;
    margin-top: 30px;
    /deep/ .el-button{
      min-width: 110px;
      margin-right: 20px !important;
    }
  }
  /deep/ .agree-dialog{
    display: flex;
    flex-direction: column;
    align-items: center;
    h3{
      margin-top: 20px;
    }
    .copy-code{
      margin-top: 15px;
      h4{
        display: inline-block;
        color: #C70009;
      }
    }
    .el-button{
      margin: 30px 24px 0;
    }
  }
</style>