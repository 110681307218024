<template>
  <div>
    <el-card>
      <el-descriptions :column="3">
        <el-descriptions-item label="操作类型">{{bizInfo.handlerType}}</el-descriptions-item>
        <el-descriptions-item label="操作编号">{{bizInfo.taskNo}}</el-descriptions-item>
        <el-descriptions-item label="任务状态">{{bizInfo.taskStatus}}</el-descriptions-item>
      </el-descriptions>
    </el-card>
    <!-- 票据基本信息 -->
    <el-card>
      <WorkbenchPublicTitle :superInfoList="baseInfoList">
        <template v-slot:titleText>
          <h3>票据基本信息</h3>
        </template>
      </WorkbenchPublicTitle>
      <el-table :data="baseInfoList" border class="mt20">
        <el-table-column prop="packageNo" label="票据包号"></el-table-column>
        <el-table-column prop="packageSum" label="票据包金额"></el-table-column>
        <el-table-column prop="acceptor" label="承兑人"></el-table-column>
        <el-table-column prop="expireDate" label="票据到期日"></el-table-column>
        <el-table-column prop="ticketRange" label="子票区间"></el-table-column>
      </el-table>
    </el-card>
    <!-- 收款信息 -->
    <el-card>
      <el-descriptions title="收款信息" :column="2" class="mt30">
        <el-descriptions-item label="收款人">{{payeeInfo.compName}}</el-descriptions-item>
        <el-descriptions-item label="收款账号">{{payeeInfo.acctNo}}</el-descriptions-item>
        <el-descriptions-item label="开户行">{{payeeInfo.acctBankName}}</el-descriptions-item>
        <el-descriptions-item label="开户行行号">{{payeeInfo.acctBankNo}}</el-descriptions-item>
        <el-descriptions-item label="清算方式">{{payeeInfo.clrWay}}</el-descriptions-item>
      </el-descriptions>
    </el-card>
    <!-- 提示付款应答-待提示付款应答确认 -->
    <div class="btn-group" v-if="bizInfo.handlerNodeCode == 40301010 || bizInfo.handlerNodeCode == 40301040">
      <el-button type="primary" @click="handleConfirmAgree">同意付款应答</el-button>
      <el-button type="warning" @click="rejectDialogVisible = true">拒绝付款应答</el-button>
      <el-button @click="$router.go(-1)">返回</el-button>
    </div>
    <!-- 提示付款应答-待提示付款应答复核 -->
    <div class="btn-group" v-if="bizInfo.handlerNodeCode == 40301020">
      <el-button type="primary" @click="handleReview(1)" v-if="buttonName">同意付款应答</el-button>
      <el-button type="primary" @click="handleReview(2)" v-else>拒绝付款应答</el-button>
      <el-button type="warning" @click="handleReviewRefuse(3)">审核驳回</el-button>
      <el-button @click="$router.go(-1)">返回</el-button>
    </div>
    <!--同意付款应答-->
		<el-dialog :visible.sync="successDialogVisible"
		           width="50%"
		           :show-close="false"
		           :close-on-click-modal="false"
		           @close="$router.go(-1)">
			<div class="agree-dialog">
				<img src="~@/assets/img/ticket/cg.png" alt="">
				<h3>{{copyText}}</h3>
				<div class="copy-code">
					操作编号：<h4>{{copyCode}}</h4>
				</div>
				<el-button type="primary" @click="handleCopy">点击复制</el-button>
			</div>
			<span slot="footer" class="dialog-footer">
		    <el-button @click="successDialogVisible = false">关 闭</el-button>
		  </span>
		</el-dialog>

    <!--拒绝付款应答弹框-->
		<el-dialog
						title="拒绝理由"
						:visible.sync="rejectDialogVisible"
						width="40%"
						@close="rejectDialogClosed">
			<el-form :model="rejectForm"
			         :rules="rejectFormRules"
			         ref="rejectFormRef"
			         class="reject-form">
				<el-form-item prop="reject">
					<el-input v-model="rejectForm.reject" type="textarea" resize="none"></el-input>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
		    <el-button @click="rejectDialogVisible = false">关 闭</el-button>
        <!-- 待提示付款应答确认40301010/待修改40301040 -- 拒绝付款应答 -->
		    <el-button type="primary" @click="handleRejectSubmit" v-if="bizInfo.handlerNodeCode == 40301040 || bizInfo.handlerNodeCode == 40301010">确 定</el-button>
        <!-- 待提示付款应答复核 审核驳回 -->
		    <el-button type="primary" @click="handleRefusalSubmit" v-if="bizInfo.handlerNodeCode == 40301020">确 定</el-button>
		  </span>
		</el-dialog>

    <!--短信验证-->
		<el-dialog
						title="短信验证"
						:visible.sync="messageDialogVisible"
						:close-on-click-modal="false"
						width="40%"
						@close="messageDialogClosed">
			<el-form :model="messageForm"
			         :rules="messageFormRules"
			         ref="messageFormRef"
			         class="message-form">
				<el-form-item label="操作员手机号" prop="operatorMobile">
					{{mobile}}
				</el-form-item>
				<el-form-item label="短信验证码" prop="verifyCode">
					<el-input v-model="messageForm.verifyCode" class="verify-code"></el-input>
					<DelayButton />
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button type="primary" @click="handleMessageSubmit">提交</el-button>
		    <el-button @click="messageDialogVisible = false">关闭</el-button>
		  </span>
		</el-dialog>
  </div>
</template>

<script>
  // 票面预览/票面下载
  import WorkbenchPublicTitle from "@/views/Ticket/routerCpn/workbench/publicCpn/WorkbenchPublicTitle";
  // 倒计时按钮
  import DelayButton from "@/views/Ticket/childrenCpn/DelayButton";
  export default {
    name: 'Payment',
    props: ['bizInfo', 'payResp', 'baseInfoList', 'payeeInfo'],
    components: {
      WorkbenchPublicTitle,
      DelayButton
    },
    data(){
      return {
        approvalCode: null, // 审批代码	1：同意、2：拒绝、3：驳回
        superInfoList: [], // 公共组件 - 票面预览/下载
        mobile: this.$store.state.getUser, //手机号
        copyCode: '', // 操作编号
        copyText: '', // 操作提示文字
        successDialogVisible: false, // 控制同意付款应答弹框的显示与隐藏
        rejectDialogVisible: false, // 控制拒绝付款应答弹框的显示与隐藏
        // 拒绝付款应答表单数据
        rejectForm: {
          reject: ''// 拒绝付款应答意见
        },
        // 拒绝付款应答表单验证规则
        rejectFormRules: {
          reject: [
            { required: true, message: '请输入拒绝理由', trigger: 'blur' }
          ]
        },
        messageDialogVisible: false, // 控制短信弹框的显示与隐藏
        // 短信验证表单数据
        messageForm: {
          verifyCode: ''
        },
        // 短信验证表单验证规则
        messageFormRules:{
          verifyCode: [
            { required: true, message: '请输入验证码', trigger: 'blur' }
          ]
        },
      }
    },
    computed:{
      // 待提示付款应答复核 -- 提交按钮展示的名字
      buttonName(){
        if(this.payResp){
          return true
        }else{
          return false
        }
      }
    },
    methods: {
      // 待提示付款应答确认/待修改 -- 同意付款应答
      async handleConfirmAgree(){
        let params = {
          ticketId: this.bizInfo.ticketId,
          approvalCode: 1
        }
        const {data: res} = await this.$auth.post('/cpiaoju-ticket/workbench/task/todo/present/pay/approval', this.$qs.stringify(params))
        if(res.code !== 200) return this.$message.error(res.msg)
        this.successDialogVisible = true
        this.copyText = res.data.text
        this.copyCode = res.data.handlerNo
      },
      // 待提示付款应答确认/待修改 -- 拒绝付款应答
      handleRejectSubmit(){
        this.$refs.rejectFormRef.validate(async valid => {
          if(!valid) return
          let params = {
            ticketId: this.bizInfo.ticketId,
            remark: this.rejectForm.reject,
            approvalCode: 2
          }
          const {data: res} = await this.$auth.post('/cpiaoju-ticket/workbench/task/todo/present/pay/approval', this.$qs.stringify(params))
          if(res.code !== 200) return this.$message.error(res.msg)
          this.successDialogVisible = true
          this.copyText = res.data.text
          this.copyCode = res.data.handlerNo
        })
      },
      // 拒绝付款应答 -- 关闭
      rejectDialogClosed(){
        this.$refs.rejectFormRef.resetFields()
      },
      // 待提示付款应答复核 -- 同意付款应答/拒绝付款应答
      handleReview(approvalCode){
        this.approvalCode = approvalCode
        this.messageDialogVisible = true
      },
      // 待提示付款应答复核 -- 审核驳回
      handleReviewRefuse(approvalCode){
        this.approvalCode = approvalCode
        this.rejectDialogVisible = true
      },
      // 待提示付款应答复核 -- 审核驳回 -- 提交
      handleRefusalSubmit(){
        this.$refs.rejectFormRef.validate(async valid => {
          if(!valid) return
            let params = {
            ticketId: this.bizInfo.ticketId,
            remark: this.rejectForm.reject,
            approvalCode: 3
          }
          const {data: res} = await this.$auth.post('/cpiaoju-ticket/workbench/task/todo/present/pay/approval', this.$qs.stringify(params))
          if(res.code !== 200) return this.$message.error(res.msg)
          this.rejectDialogVisible = false
          this.$router.push({name: 'taskWaitList'})
        })
      },
      // 待提示付款应答复核 -- 同意付款应答/拒绝付款应答 -- 提交
      handleMessageSubmit(){
        this.$refs.messageFormRef.validate(async valid => {
          if(!valid) return
          // 短信验证参数
          let verifyParams = {
            ticketId: this.bizInfo.ticketId,
            handlerTypeCode: this.bizInfo.handlerTypeCode,
            handlerNodeCode: this.bizInfo.handlerNodeCode,
            smsCode: this.messageForm.verifyCode
          }
          // 同意付款应答/拒绝付款应答 -- 参数
          let params = {
            ticketId: this.bizInfo.ticketId,
            approvalCode: this.approvalCode
          }
          // 短信验证
          const {data: res} = await this.$auth.post("/cpiaoju-ticket/workbench/task/todo/verifyCode", this.$qs.stringify(verifyParams));
          if (res.code === 200) {
            const {data: result} = await this.$auth.post('/cpiaoju-ticket/workbench/task/todo/present/pay/approval', this.$qs.stringify(params))
            if (result.code !== 200) return this.$message.error(result.msg)
            this.copyText = result.data.text;
            this.copyCode = result.data.handlerNo;
            this.successDialogVisible = true;
          }else {
            this.messageDialogVisible = false
            this.$message.error(res.msg);
          }
        })
      },
      // 短信验证 -- 关闭
      messageDialogClosed(){
        this.$refs.messageFormRef.resetFields()
      },
      // 点击复制
      handleCopy(){
        this.$copyText(this.copyCode).then(res => {
          this.$message.success('复制成功')
          this.successDialogVisible = false
          return false;
        },err => {
          this.$message.error('复制失败')
          this.successDialogVisible = true
        })
      },
    }
  }
</script>

<style lang="less" scoped>
  /deep/ .el-descriptions__body{
    padding-left: 20px;
  }
  /*按钮*/
  /deep/ .btn-group{
    text-align: center;
    margin-top: 30px;
    .el-button{
      min-width: 110px;
      margin: 0 20px !important;
    }
  }
  /*同意收票弹框*/
  /deep/ .agree-dialog{
    display: flex;
    flex-direction: column;
    align-items: center;
    h3{
      margin-top: 20px;
    }
    .copy-code{
      margin-top: 15px;
      h4{
        display: inline-block;
        color: #C70009;
      }
    }
    .el-button{
      margin: 30px 24px 0;
    }
  }
  // 短信验证框
  .message-form{
    /deep/ .el-form-item__error{
      left: 90px !important;
    }
  }
</style>