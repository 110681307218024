<template>
	<div>
		<!--承兑应答 - 待承兑应答复核-->
		<div class="btn-group">
			<el-button type="primary"
			           v-if="acceptResp === 1"
								 @click="handleAgree">
				同意承兑应答
			</el-button>
			<el-button type="primary" v-else @click="handleRefuse">拒绝承兑应答</el-button>
			<el-button type="warning" @click="returnDialogVisible = true">审核驳回</el-button>
			<el-button @click="$router.go(-1)">返回</el-button>
		</div>
		<!--短信验证-->
		<el-dialog
						title="短信验证"
						:visible.sync="messageDialogVisible"
						:close-on-click-modal="false"
						width="50%"
						@close="messageDialogClosed">
			<el-form :model="messageForm"
			         :rules="messageFormRules"
			         ref="messageFormRef"
			         class="message-form">
				<el-form-item label="操作员手机号" prop="operatorMobile">
					{{mobile}}
				</el-form-item>
				<el-form-item label="短信验证码" prop="verifyCode">
					<el-input v-model="messageForm.verifyCode" class="verify-code"></el-input>
					<DelayButton />
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button type="primary" v-if="acceptResp === 1" @click="handleSubmit">提交</el-button>
				<el-button type="primary" v-else @click="handleRefuseSubmit">提交</el-button>
		    <el-button @click="messageDialogVisible = false">关闭</el-button>
		  </span>
		</el-dialog>
		<!--收票申请成功-->
		<el-dialog :visible.sync="successDialogVisible"
		           width="40%"
		           :show-close="false"
		           :close-on-click-modal="false"
		           @close="$router.go(-1)">
			<div class="agree-dialog">
				<img src="~@/assets/img/ticket/cg.png" alt="">
				<h3>{{copyText}}</h3>
				<div class="copy-code">
					操作编号：<h4>{{copyCode}}</h4>
				</div>
				<el-button type="primary" @click="handleCopy">点击复制</el-button>
			</div>
			<span slot="footer" class="dialog-footer">
		    <el-button @click="successDialogVisible = false">关 闭</el-button>
		  </span>
		</el-dialog>
		<!--审核驳回弹框-->
		<el-dialog
						title="驳回意见"
						:visible.sync="returnDialogVisible"
						width="40%"
						@close="returnDialogClosed">
			<el-form :model="returnForm"
			         :rules="returnFormRules"
			         ref="returnFormRef"
			         class="message-form">
				<el-form-item prop="returnIdea">
					<el-input v-model="returnForm.returnIdea" type="textarea" resize="none"></el-input>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button type="primary" @click="handleReturnSubmit">确 定</el-button>
		    <el-button @click="returnDialogVisible = false">关 闭</el-button>
		  </span>
		</el-dialog>
	</div>
</template>

<script>
  import DelayButton from "@/views/Ticket/childrenCpn/DelayButton";
  export default {
    name: "AcceptReview",
	  props: ['acceptResp', 'ticketId', 'handlerTypeCode', 'handlerNodeCode'],
    components: {
      DelayButton
    },
    data(){
      return {
        mobile: '', //手机号
        copyCode: '', // 操作编号
        copyText: '', // 操作提示文字
        messageDialogVisible: false, // 控制短信弹框的显示与隐藏
        successDialogVisible: false, // 控制收票成功弹框的显示与隐藏
        // 短信验证表单数据
        messageForm: {
          verifyCode: ''
        },
        // 短信验证表单验证规则
        messageFormRules:{
          verifyCode: [
            { required: true, message: '请输入验证码', trigger: 'blur' }
          ]
        },
        returnDialogVisible: false, // 控制审核驳回弹框的显示与隐藏
	      // 驳回意见表单
        returnForm: {
          returnIdea: '', //驳回意见
        },
        returnFormRules: {
          returnIdea: [
	          { required: true, message: '请输入驳回意见', trigger: 'blur' }
          ]
        }
      }
    },
	  methods: {
      // 同意承兑应答|获取手机号
      async handleAgree(){
        const {data: res} = await this.$auth.get("/cpiaoju-ticket/common/mobile");
        if (res.code !== 200) return this.$message.error(res.msg);
        this.mobile = res.data.mobile;
        this.messageDialogVisible = true
      },
		  // 拒绝承兑应答 | 获取手机号
      async handleRefuse(){
        const {data: res} = await this.$auth.get("/cpiaoju-ticket/common/mobile");
        if (res.code !== 200) return this.$message.error(res.msg);
        this.mobile = res.data.mobile;
        this.messageDialogVisible = true
      },
      // 短信验证关闭
      messageDialogClosed(){
        this.$refs.messageFormRef.resetFields()
      },
		  // 同意承兑应答 | 短信验证提交
      handleSubmit(){
        this.$refs.messageFormRef.validate(async valid => {
          if (!valid) return;
          // 短信验证参数
          let verifyParams = {
            ticketId: this.ticketId,
            handlerTypeCode: this.handlerTypeCode,
            handlerNodeCode: this.handlerNodeCode,
            smsCode: this.messageForm.verifyCode
          };
          //确认提交参数
          let submitParams = {
            ticketId: this.ticketId,
            handlerTypeCode: this.handlerTypeCode,
            handlerNodeCode: this.handlerNodeCode,
          }
          // 短信验证
          const {data: res} = await this.$auth.post("/cpiaoju-ticket/workbench/task/todo/verifyCode",this.$qs.stringify(verifyParams));
          if (res.code === 200) {
            //待承兑应答复核-同意承兑应答
            const {data: result} = await this.$auth.post("/cpiaoju-ticket/workbench/task/todo/mt/acceptResponse/recheck/agreeResp", this.$qs.stringify(submitParams));
            console.log(result);
            this.copyText = result.data.text;
            this.copyCode = result.data.handlerNo;
            this.messageDialogVisible = false;
            this.successDialogVisible = true
          }else {
            this.$message.error(res.msg);
          }
        })
      },
		  // 拒绝承兑应答 | 短信验证提交
      handleRefuseSubmit(){
        this.$refs.messageFormRef.validate(async valid => {
          if (!valid) return;
          // 短信验证参数
          let verifyParams = {
            ticketId: this.ticketId,
            handlerTypeCode: this.handlerTypeCode,
            handlerNodeCode: this.handlerNodeCode,
            smsCode: this.messageForm.verifyCode
          };
          //确认提交参数
          let submitParams = {
            ticketId: this.ticketId,
            handlerTypeCode: this.handlerTypeCode,
            handlerNodeCode: this.handlerNodeCode,
          }
          // 短信验证
          const {data: res} = await this.$auth.post("/cpiaoju-ticket/workbench/task/todo/verifyCode", this.$qs.stringify(verifyParams));
          if (res.code === 200) {
            //待承兑应答复核-同意承兑应答
            const {data: result} = await this.$auth.post("/cpiaoju-ticket/workbench/task/todo/mt/acceptResponse/recheck/refuseResp", this.$qs.stringify(submitParams));
            this.copyText = result.data.text;
            this.copyCode = result.data.handlerNo;
            this.messageDialogVisible = false;
            this.successDialogVisible = true
          }else {
            this.$message.error(res.msg);
          }
        })
      },
		  // 驳回意见弹框关闭
      returnDialogClosed(){
        this.$refs.returnFormRef.resetFields()
      },
		  //审核驳回
      handleReturnSubmit(){
        this.$refs.returnFormRef.validate(async valid => {
          if (!valid) return;
          let params = {
            ticketId: this.ticketId,
            handlerTypeCode: this.handlerTypeCode,
            handlerNodeCode: this.handlerNodeCode,
            refuse: this.returnForm.returnIdea
          }
          // 承兑应答-待承兑应答复核-审核驳回
	        const {data: res} = await this.$auth.post("/cpiaoju-ticket/workbench/task/todo/mt/acceptResponse/recheck/refuse", this.$qs.stringify(params))
          if(res.code == 200){
            this.returnDialogVisible = false;
            this.$router.go(-1)
          }else {
            this.$message.error(res.msg);
          }
        })
      },
      // 点击复制
      handleCopy(){
        this.$copyText(this.copyCode).then(res => {
          this.$message.success('复制成功')
          this.successDialogVisible = false
          return false;
        },err => {
          this.$message.error('复制失败')
          this.successDialogVisible = true
        })
      },
	  }
  }
</script>

<style scoped>

</style>