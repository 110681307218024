<template>
  <div id="box">
    <el-card>
      <el-descriptions :column="3">
        <el-descriptions-item label="操作类型">{{discountModify.handlerType}}</el-descriptions-item>
        <el-descriptions-item label="操作编号">{{discountModify.taskNo}}</el-descriptions-item>
        <el-descriptions-item label="任务状态">{{discountModify.taskStatus}}</el-descriptions-item>
      </el-descriptions>
    </el-card>
    <!--贴现信息-->
    <el-card>
      <h3 class="mt20">贴现信息</h3>
      <ul class="discount-info-list">
        <li>
          <span>贴现机构：</span>
          <em>{{discountModify.orgFullCnName}}</em>
        </li>
        <li>
          <span>贴现行号： </span>
          <em>{{discountModify.orgBankCode}}</em>
        </li>
        <li>
          <span>贴现金额（元）：</span>
          <em>{{discountModify.discountAmount}}</em>
        </li>
        <li>
          <span>申请日期：</span>
          <em>{{discountModify.applyTime}}</em>
        </li>
        <li>
          <span>贴现利率（%）：</span>
          <em>{{discountModify.discountRate}}</em>
        </li>
        <li>
          <span>到账金额（元）：</span>
          <em>{{discountModify.arrivalAmount}}</em>
        </li>
        <li>
          <span>平台服务费率（%）：</span>
          <em>{{discountModify.platformServiceRate}}</em>
        </li>
        <li>
          <span>应付服务费（元）：</span>
          <em>{{discountModify.payServiceCost}}</em>
        </li>
        <li>
          <span>收款账号：</span>
          <em>{{discountModify.recBankAccount}}</em>
        </li>
        <li>
          <span>收款账户行号：</span>
          <em>{{discountModify.recLegalNumber}}</em>
        </li>
        <li>
          <span>转让标记：</span>
          <em>{{discountModify.transferFlagName}}</em>
        </li>
        <li>
          <span>收款账户银行：</span>
          <em>{{discountModify.recBankName}}</em>
        </li>
        <li>
          <span>贴现种类：</span>
          <em>{{discountModify.discountType}}</em>
        </li>
        <li>
          <span>结算方式：</span>
          <em>{{discountModify.settlementModel === 'ST01' ? '线上' : '线下'}}</em>
        </li>
        <li>
          <span>付息方式：</span>
          <em>{{discountModify.payInterestType}}</em>
        </li>
        <li>
          <label>备注：</label>
          <em>{{discountModify.remark}}</em>
        </li>
      </ul>
    </el-card>
    <!--缴纳平台服务费-->
    <el-card>
      <h3 class="mt20">缴纳平台服务费</h3>
      <ul class="discount-info-list">
        <li>
          <span>账户名称：</span>
          <em>{{discountModify.lxAccountName}}</em>
        </li>
        <li>
          <span>银行全称：</span>
          <em>{{discountModify.lxBankName}}</em>
        </li>
        <li>
          <span>银行账户：</span>
          <em>{{discountModify.lxBankAccount}}</em>
        </li>
        <li>
          <span>大额行号：</span>
          <em>{{discountModify.lxLegalNumber}}</em>
        </li>
        <li>
          <label>缴纳凭证：</label>
          <el-upload
              action="http://117.122.218.200:11001/cpiaoju-ticket/front-company-register/uploadToOss"
              accept=".jpg,jpeg,.png"
              class="upload-paymentUrl"
              list-type="picture-card"
              name="multipartFile"
              :show-file-list="false"
              :on-success="(response, file, fileList)=> contractForm.payVouchersUrl = response.data">
            <img class="upload-img paymentUrl"
                 v-if="contractForm.payVouchersUrl"
                 :src="contractForm.payVouchersUrl"
                 alt=""/>
            <i class="el-icon-plus" v-else></i>
          </el-upload>
        </li>
        <li>
          <label>缴纳时间：</label>
          <el-date-picker
              v-model="contractForm.payTime"
              type="date"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              placeholder="选择日期">
          </el-date-picker>
        </li>
      </ul>
    </el-card>
    <!--发票信息-->
    <div class="file-bill">
      <!--上传发票头部-->
      <div class="file-bill-top">
        <div class="file-bill-top-left">
          <span>发票信息</span>
          <span>请上传文件格式为png、jpeg、jpg格式的文件，单个文件大小在2M以内</span>
        </div>
        <div class="file-bill-top-right">
          <el-button v-if="invoiceList.length !== 0" @click="billDialogVisible = true" >全部发票</el-button>
          <el-upload
              class="upload-demo"
              action="http://117.122.218.200:11001/cpiaoju-ticket/discount/invoiceOcr"
              :show-file-list="false"
              name="file"
              :data="{
                id: this.discountModify.ticketBizId,
                isToDo: true
              }"
              multiple
              accept=".png,.jpeg,.jpg"
              :before-upload="handleBeforeUpload"
              :on-success="handleSuccess">
            <el-button type="primary">批量上传发票</el-button>
          </el-upload>
        </div>
      </div>
      <!--上传发票展示区域-->
      <div class="file-bill-box">
        <div class="file-bill-left">
          <div class="file-big-img">
            <img :src="discernForm.invoiceImgOssUrl"
                 v-if="discernForm.invoiceImgOssUrl"
                 alt="">
            <div class="file-big-num"
                 v-show="isInvoiceList">
              <span>{{invoiceCurrentIndex + 1}}</span>/{{invoiceList.length}}
            </div>
          </div>
          <div class="file-total-money">
            价税合计：<span>{{total.toFixed(2)}}</span>元
          </div>
          <!--轮播图-->
          <div class="carousel-box">
            <Carousel class="carousel"
                      :list="invoiceList"
                      @handleCarouselClick="handleCarouselClick"
                      @carouselRemove="carouselRemove"/>
          </div>
        </div>
        <div class="file-bill-right">
          <el-form :model="discernForm"
                   :rules="rulesForm"
                   ref="formRef"
                   label-width="120px">
            <el-form-item label="校验结果：">
              <span class="verify-btn pass" v-if="discernForm.status =='1'">识别成功</span>
              <span class="verify-btn failure" v-if="discernForm.status =='2'">识别失败</span>
              <span class="verify-btn failure" v-if="discernForm.status =='3'">重复发票</span>
            </el-form-item>
            <el-form-item label="购方名称：" prop="purchaserName">
              <el-input v-model="discernForm.purchaserName"></el-input>
            </el-form-item>
            <el-form-item label="销方名称：" prop="sellerName">
              <el-input v-model="discernForm.sellerName"></el-input>
            </el-form-item>
            <el-form-item label="发票代码：" prop="invoiceCode">
              <el-input v-model="discernForm.invoiceCode"></el-input>
            </el-form-item>
            <el-form-item label="发票号码：" prop="invoiceNum">
              <el-input v-model="discernForm.invoiceNum"></el-input>
            </el-form-item>
            <el-form-item label="开票日期：" prop="invoiceDate">
              <el-date-picker
                  v-model="discernForm.invoiceDate"
                  type="date"
                  format="yyyy-MM-dd"
                  value-format="yyyy-MM-dd"
                  placeholder="选择日期">
              </el-date-picker>
            </el-form-item>
            <el-form-item label="价税合计：" prop="taxIncludedAmount">
              <el-input v-model="discernForm.taxIncludedAmount"
                        style="width: 90%!important;margin-right: 5px;"></el-input>元
            </el-form-item>
            <el-form-item label="不含税金额：" prop="noTaxAmount">
              <el-input v-model="discernForm.noTaxAmount"
                        style="width: 90%!important;margin-right: 5px;"></el-input>元
            </el-form-item>
            <el-form-item label="后6位校验码：" prop="checkCode" v-show="discernForm.invoiceType !== '专用发票'">
              <el-input v-model="discernForm.checkCode"></el-input>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
    <!--合同信息-->
    <div class="contract-info mt30">
      <h3>合同信息</h3>
      <el-form :model="contractForm"
               :rules="rulesForm"
               ref="formRef"
               label-width="140px"
               class="mt20">
        <el-form-item label="合同编号：" prop="contractNo">
          <el-input v-model="contractForm.contractNo" 
                    placeholder="32位以内的数字或字母组成"
                    maxlength="32"></el-input>
        </el-form-item>
        <el-form-item label="合同日期：" prop="contractSignDate">
          <el-date-picker
              v-model="contractForm.contractSignDate"
              type="date"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              placeholder="选择日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item type="number" label="合同金额（元）：" prop="contractAmt">
          <el-input v-model="contractForm.contractAmt"></el-input>
        </el-form-item>
        <el-form-item label="合同信息分类：" prop="tradeInfoType">
          <el-select placeholder="请选择" v-model="contractForm.tradeInfoType">
            <el-option label="合同" value="1"></el-option>
            <el-option label="订单" value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="贸易背景方式：" prop="tradeMode">
          <el-select placeholder="请选择" v-model="contractForm.tradeMode">
            <el-option label="货物贸易" value="1"></el-option>
            <el-option label="服务贸易" value="2"></el-option>
            <el-option label="货服贸易" value="3"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="合同附件：" required>
          <el-upload
              action="http://117.122.218.200:11001/cpiaoju-ticket/front-company-register/uploadToOss"
              accept=".jpg,jpeg,.bmp,.png,.pdf,.zip,.rar"
              class="discount-contract-img"
              list-type="picture-card"
              name="multipartFile"
              :show-file-list="false"
              :before-upload="handleContractBefore"
              :on-success="handleContractSuccess">
            <img class="upload-img aa"
                 v-if="isPdf"
                 :src="pdfImg"
                 alt="">
            <img class="upload-img bb"
                 v-if="isImg"
                 :src="contractForm.tradeFileUrl"
                 alt=""/>
            <i class="el-icon-plus" v-else></i>
            <div slot="tip" class="el-upload__tip">
              提示说明：<br/>
              1、可上传购销合同或订单证明文件<br/>
              2、仅允许上传类型为jpg、jpeg、bmp、png、pdf、zip、rar，文件名称不超过50位，并且不大于2MB的文件
            </div>
          </el-upload>
        </el-form-item>
      </el-form>
    </div>
    <!--母票基本信息-->
    <el-card>
      <WorkbenchPublicTitle :superInfoList="discountModify.discountTicketInfoVOList">
        <template v-slot:titleText>
          <h3>母票基本信息</h3>
        </template>
      </WorkbenchPublicTitle>
      <el-table :data="discountModify.discountTicketInfoVOList" border class="mt20">
        <el-table-column prop="packageNo" label="票据包号"></el-table-column>
        <el-table-column prop="packageSum" label="票据包金额"></el-table-column>
        <el-table-column prop="acceptorPersonName" label="承兑人"></el-table-column>
        <el-table-column prop="expireTime" label="票据到期日"></el-table-column>
        <el-table-column prop="ticketRange" label="子票区间"></el-table-column>
      </el-table>
    </el-card>
    <!--子票基本信息-->
    <el-card v-if="discountModify.ticketChildVOList !== null">
      <h3>子票基本信息</h3>
      <el-table :data="discountModify.ticketChildVOList" border class="mt20">
        <el-table-column prop="childTicketType" label="子票信息"></el-table-column>
        <el-table-column prop="ticketAffiliation" label="票据归属"></el-table-column>
        <el-table-column prop="childPackageSum" label="子票包金额"></el-table-column>
        <el-table-column prop="transferFlagName" label="转让标记"></el-table-column>
      </el-table>
    </el-card>
    <div class="btn-group">
      <el-button type="primary" @click="handleSubmit">确认提交</el-button>
      <el-button type="warning" @click="closeDialogVisible = true">关闭</el-button>
      <el-button @click="$router.go(-1)">返回</el-button>
    </div>
    <!--全部发票-->
    <el-dialog title="全部发票"
               :visible.sync="billDialogVisible"
               width="90%">
      <ul class="bill-all">
        <li v-for="(item, index) in invoiceList">
          <div class="bill-all-num">{{index + 1}}</div>
          <div class="bill-all-img">
            <img :src="item.invoiceImgOssUrl" alt="" v-if="item.invoiceImgOssUrl">
          </div>
          <div class="bill-all-input">
            <el-form :inline="true"
                     label-width="120px">
              <el-form-item label="校验结果：">
                <span class="verify-btn pass" v-if="item.status =='1'">识别成功</span>
                <span class="verify-btn failure" v-if="item.status =='2'">识别失败</span>
                <span class="verify-btn failure" v-if="item.status =='3'">重复发票</span>
              </el-form-item>
              <el-form-item label="购方名称：">
                <el-input v-model="item.purchaserName"></el-input>
              </el-form-item>
              <el-form-item label="销方名称：">
                <el-input v-model="item.sellerName"></el-input>
              </el-form-item>
              <el-form-item label="发票代码：">
                <el-input v-model="item.invoiceCode"></el-input>
              </el-form-item>
              <el-form-item label="发票号码：">
                <el-input v-model="item.invoiceNum"></el-input>
              </el-form-item>
              <el-form-item label="开票日期：">
                <el-date-picker
                        v-model="item.invoiceDate"
                        type="date"
                        format="yyyy-MM-dd"
                        value-format="yyyy-MM-dd"
                        placeholder="选择日期">
                </el-date-picker>
              </el-form-item>
              <el-form-item label="价税合计：">
                <el-input v-model="item.taxIncludedAmount"
                          style="width: 89%!important;margin-right: 5px;"></el-input>元
              </el-form-item>
              <el-form-item label="不含税金额：">
                <el-input v-model="item.noTaxAmount"
                          style="width: 89%!important;margin-right: 5px;"></el-input>元
              </el-form-item>
              <el-form-item label="后6位校验码：">
                <el-input v-model="item.checkCode" v-show="item.invoiceType !== '专用发票'"></el-input>
              </el-form-item>
            </el-form>
          </div>
        </li>
      </ul>
    </el-dialog>
    <!--错误发票弹框-->
    <el-dialog
            title="提示"
            :visible.sync="billErrorDialogVisible"
            width="50%">
      <ul class="bill-error">
        <li v-for="(item,index) in errorList" :key="index">{{item}}</li>
      </ul>
      <span slot="footer" class="dialog-footer">
		    <el-button @click="billErrorDialogVisible = false">取 消</el-button>
		    <el-button type="primary" @click="billErrorDialogVisible = false">确 定</el-button>
		  </span>
    </el-dialog>
    <!--确认提交弹框-->
    <el-dialog :visible.sync="confirmDialogVisible"
               width="40%"
               :show-close="false"
               :close-on-click-modal="false"
               @close="$router.go(-1)">
      <div class="agree-dialog">
        <img src="~@/assets/img/ticket/cg.png" alt="">
        <h3>{{copyText}}</h3>
        <div class="copy-code">
          操作编号：<h4>{{copyCode}}</h4>
        </div>
        <el-button type="primary" @click="handleCopy">点击复制</el-button>
      </div>
      <span slot="footer" class="dialog-footer">
		    <el-button @click="confirmDialogVisible = false">关 闭</el-button>
		  </span>
    </el-dialog>
    <!--关闭弹框-->
    <el-dialog
            title="关闭原因"
            :visible.sync="closeDialogVisible"
            width="40%"
            @close="closeDialogClosed">
      <el-form :model="closeForm"
               :rules="closeFormRules"
               ref="closeFormRef"
               class="message-form">
        <el-form-item prop="reject">
          <el-input v-model="closeForm.reject" type="textarea" resize="none"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
		    <el-button @click="closeDialogVisible = false">关 闭</el-button>
		    <el-button type="primary" @click="handleCloseSubmit">确 定</el-button>
		  </span>
    </el-dialog>
  </div>
</template>

<script>
  // 轮播图
  import Carousel from '@/views/Ticket/childrenCpn/CarouselSecond'
  // 票面预览/票面下载
  import WorkbenchPublicTitle from "@/views/Ticket/routerCpn/workbench/publicCpn/WorkbenchPublicTitle";
  export default {
    name: "DiscountModify",
    props: ['discountModify'],
    components: {
      Carousel,
      WorkbenchPublicTitle
    },
    mounted() {
      this.invoiceList = this.discountModify.invoiceList;
      // this.discernForm = this.invoiceList[0]
      this.contractForm = {...this.discountModify}
    },
    data(){
      // 验证合同编号的正则表达式
      const regContractNo = /^[A-Za-z0-9]+$/;
      var checkContractNo = (rule, value, callback) => {
        if (regContractNo.test(value)) {
          callback();
        }
        callback(new Error("合同编号需要32位数字与字母组合"));
      };
      return {
        isPdf: false, // 判断是否是pdf
        isImg: false, // 判断是否是图片
        billDialogVisible: false, //控制全部发票的显示与隐藏
        invoiceList: [],// 发票列表-上传后返回的识别数据
        invoiceCurrentIndex: 0, //发票列表当前数
        // 发票信息 - 识别出来的信息
        discernForm: {},
        // 合同信息
        contractForm: {},
        // 发票信息 - 验证规则
        rulesForm: {
          purchaserName: [
            { required: true, message: '请输入购方名称', trigger: 'blur' }
          ],
          sellerName: [
            { required: true, message: '请输入销方名称', trigger: 'blur' }
          ],
          invoiceCode: [
            { required: true, message: '请输入发票代码', trigger: 'blur' }
          ],
          invoiceNum: [
            { required: true, message: '请输入发票号码', trigger: 'blur' }
          ],
          invoiceDate: [
            { required: true, message: '请输入开票日期', trigger: 'blur' }
          ],
          taxIncludedAmount: [
            { required: true, message: '请输入价税合计', trigger: 'blur' }
          ],
          noTaxAmount: [
            { required: true, message: '请输入不含税金额', trigger: 'blur' }
          ],
          checkCode: [
            { required: true, message: '请输入后6位校验码', trigger: 'blur' }
          ],
          contractNo: [
            { required: true, message: '请输入合同编号', trigger: 'blur' },
            { min: 0, max: 32, message: "只能输入32位字符", trigger: "blur", },
            { validator: checkContractNo, trigger: "blur" }
          ],
          contractSignDate: [
            { required: true, message: '请输入合同日期', trigger: 'blur' }
          ],
          contractAmt: [
            { required: true, message: '请输入合同金额', trigger: 'blur' }
          ],
          tradeInfoType: [
            { required: true, message: '请选择合同信息分类', trigger: 'change' }
          ],
          tradeMode: [
            { required: true, message: '请选择贸易背景方式', trigger: 'change' }
          ],
        },
        errorList: [],// 验证失败发票的列表
        billErrorDialogVisible: false, // 控制失败发票列表弹框的显示与隐藏
        pdfImg: 'https://cpiaoju.oss-cn-beijing.aliyuncs.com/lxgw/gpxt/upload_image/pdf.png', // 上传除图片外的默认图片
        confirmDialogVisible: false, // 控制确认提交弹框的显示与隐藏
        copyCode: '', // 操作编号
        copyText: '', // 操作提示文字
        closeDialogVisible: false, // 控制关闭弹框的显示与隐藏
        // 关闭意见表单数据
        closeForm: {
          reject: ''// 关闭意见
        },
        // 关闭意见表单验证规则
        closeFormRules: {
          reject: [
            { required: true, message: '请输入关闭意见', trigger: 'blur' }
          ]
        }
      }
    },
    watch: {
      // 侦听返回的合同附件尾缀的格式
      contractForm: {
        handler(value){
          let str =  value.tradeFileUrl.substring(value.tradeFileUrl.lastIndexOf('.'), value.tradeFileUrl.length)
          console.log(str)
          if (!value.tradeFileUrl){
            this.isImg = false;
            this.isPdf = false
          }else if (str === '.jpg' || str === '.jpeg' || str === '.png'){
            this.isImg = true;
            this.isPdf = false
          }else {
            this.isImg = false;
            this.isPdf = true
          }
        },
        deep: true
      }
    },
    computed: {
      // 价税合计
      total(){
        return this.invoiceList.reduce((preValue, item) => {
          return preValue + Number(item.taxIncludedAmount)
        },0)
      },
      isInvoiceList(){
        if (this.invoiceList.length == 0){
          return false
        }else {
          return true
        }
      }
    },
    methods: {
      // 监听上传发票之前的钩子
      handleBeforeUpload(file){
        let imgSize = Number(file.size / 1024 / 1024);
        if (imgSize > 2){
          this.$message.error('上传文件大小不能超过2M')
          return false
        }
        return imgSize
      },
      // 监听上传发票成功回调
      handleSuccess(res, file, fileList){
        this.invoiceList.push(res.data)
      },
      // 监听获取当前发票识别出的信息
      handleCarouselClick(value){
        this.discernForm = this.invoiceList[value]
        //当前激活的个数
        this.invoiceCurrentIndex = value
      },
      // 监听删除当前发票
      carouselRemove({item, index}){
        this.invoiceList = item
        this.invoiceCurrentIndex = index
        // 判断当前发票列表的长度用来显示 发票列表当前数
        if (item.length === 0){
          this.invoiceCurrentIndex = -1
          this.discernForm.status = ''
          this.discernForm.invoiceImgOssUrl = ''
          this.discernForm.purchaserName = ''
          this.discernForm.sellerName = ''
          this.discernForm.invoiceNum = ''
          this.discernForm.invoiceCode = ''
          this.discernForm.invoiceDate = ''
          this.discernForm.taxIncludedAmount = ''
          this.discernForm.noTaxAmount = ''
          this.discernForm.checkCode = ''
        }
      },
      // 监听上传合同前回调
      handleContractBefore(file){
        let fileNameLen = file.name.substring(0, file.name.lastIndexOf('.'));
        let imgSize = Number(file.size / 1024 / 1024);
        if(fileNameLen.length > 50){
          this.$message.error('上传文件名称不能超过50位');
          return false;
        }
        if (imgSize > 2){
          this.$message.error('上传文件大小不能超过2M');
          return false;
        }
        return imgSize || fileNameLen
      },
      // 监听上传合同附件成功
      handleContractSuccess(res, file, fileList){
        this.contractForm.tradeFileUrl = res.data;
      },
      // 确定提交
      async handleSubmit(){
        let params = {
          id: this.discountModify.ticketBizId,
          payTime: this.contractForm.payTime,
          payVouchersUrl: this.contractForm.payVouchersUrl,
          invoiceListStr: JSON.stringify(this.invoiceList),
          contractNo: this.contractForm.contractNo,
          contractSignDate: this.contractForm.contractSignDate,
          contractAmt: this.contractForm.contractAmt,
          tradeInfoType: this.contractForm.tradeInfoType,
          tradeMode: this.contractForm.tradeMode,
          tradeFileUrl: this.contractForm.tradeFileUrl
        }
        const {data: res} = await this.$auth.post('/cpiaoju-ticket/discount/edit', this.$qs.stringify(params))
        if (res.code === 200){
          this.confirmDialogVisible = true
          this.copyText = '贴现申请修改成功'
          this.copyCode = res.data
        }else {
          this.billErrorDialogVisible = true;
          this.errorList = res.data
        }
      },
      // 监听关闭的弹框
      closeDialogClosed(){
        this.$refs.closeFormRef.resetFields()
      },
      // 关闭的弹框的提交
      handleCloseSubmit(){
        this.$refs.closeFormRef.validate(async valid => {
          if (!valid) return;
          let params = {
            ticketBizId: this.discountModify.ticketBizId,
            closeReason: this.closeForm.reject
          }
          const {data: res} = await this.$auth.get('/cpiaoju-ticket/discount/close', {params});
          if (res.code !== 200) return this.$message.error(res.msg)
          this.$router.push("/ticket/taskWaitList")
        })
      },
      // 点击复制
      handleCopy(){
        this.$copyText(this.copyCode).then(res => {
          this.$message.success('复制成功')
          this.confirmDialogVisible = false
          return false;
        },err => {
          this.$message.error('复制失败')
          this.confirmDialogVisible = true
        })
      }
    }
  }
</script>

<style lang="less" scoped>
  #box{
    color: #606266 !important;
    /*标题*/
    .title{
      h3{
        display: inline-block;
        margin-right: 10px;
      }
    }
    /*按钮*/
    /deep/ .btn-group{
      text-align: center;
      margin-top: 30px;
      /deep/ .el-button{
        min-width: 110px;
        margin-right: 20px !important;
      }
    }
    /*贴现信息*/
    .discount-info-list{
      display: flex;
      flex-wrap: wrap;
      color: #606266;
      li{
        width: 50%;
        margin-top: 20px;
        > span, label{
          display: inline-block;
          width: 150px;
          text-align: right;
        }
        span::before{
          display: inline-block;
          content: "*";
          color: #c70009;
          margin-right: 3px;
        }
      }
      /*缴纳凭证*/
      /deep/ .upload-paymentUrl{
        display: inline-block;
        .paymentUrl{
          width: 100%;
          height: 100%;
        }
      }
    }
    /*发票信息*/
    .file-bill{
      margin-top: 30px;
      .file-bill-top{
        display: flex;
        justify-content: space-between;
        .file-bill-top-left{
          line-height: 40px;
          span{
            display: inline-block;
          }
          span:first-child{
            font-size: 16px;
            color: #24262B;
            font-weight: bold;
            margin-right: 10px;
          }
          span:nth-of-type(2){
            font-size: 12px;
            color: #666;
            i{
              color: #D6000F;
            }
          }
        }
        .file-bill-top-right{
          display: flex;
          .verify-btn{
            display: inline-block;
            width: 110px;
            height: 40px;
            line-height: 40px;
            text-align: center;
          }
          .el-button{
            margin: 0 10px;
          }
        }
      }
      .file-bill-box{
        display: flex;
        width: 100%;
        margin-top: 20px;
        border: 1px solid #DEDEDE;
        box-sizing: border-box;
        .file-bill-left{
          width: 60%;
          padding-bottom: 15px;
          border-right: 1px solid #dedede;
          box-sizing: border-box;
          .carousel-box{
            height: 90px;
            padding: 0 15px;
            box-sizing: border-box;
          }
          .file-big-img{
            background: #F2F2F2;
            width: 100%;
            height: 362px;
            padding: 0 50px;
            box-sizing: border-box;
            position: relative;
            .file-big-num{
              position: absolute;
              top: 10px;
              right: 10px;
              span{
                font-size: 20px;
              }
            }
            img{
              width: 100%;
              height: 100%;
            }
          }
          .file-total-money{
            color: #666666;
            padding-left: 15px;
            margin-top: 15px;
            box-sizing: border-box;
            span{
              color: #D6000F;
              font-weight: 700;
            }
          }
        }
        .file-bill-right{
          width: 40%;
          padding-right: 20px;
          padding-top: 10px;
          padding-bottom: 10px;
          box-sizing: border-box;
          .verify-btn{
            display: inline-block;
            width: 100px;
            height: 40px;
            line-height: 40px;
            text-align: center;
            border-radius: 4px;
            box-sizing: border-box;
          }
          .pass{
            background: #F6FFED;
            color: #53C41C;
            border: 1px solid #53C41C;
          }
          .failure{
            background: #FFE6E5;
            color: #F00036;
            border: 1px solid #F00036;
          }
          .el-form-item{
            margin-bottom: 18px !important;
          }
          .el-form-item:first-of-type{
            margin-bottom: 10px !important;
          }
          .el-date-editor.el-input{
            width: 100%;
          }
        }
      }
    }
    /*确认提交弹框*/
    /deep/ .agree-dialog{
      display: flex;
      flex-direction: column;
      align-items: center;
      h3{
        margin-top: 20px;
      }
      .copy-code{
        margin-top: 15px;
        h4{
          display: inline-block;
          color: #C70009;
        }
      }
      .el-button{
        margin: 30px 24px 0;
      }
    }
    /*合同信息*/
    .contract-info{
      .el-upload__tip{
        line-height: 1.5;
      }
      .el-select{
        width: 100%;
      }
      /deep/ .el-date-editor.el-input, .el-date-editor.el-input__inner{
        width: 100%;
      }
      .upload-tradeFileUrl{
        width: 148px;

      }
      .upload-tradeFileUrl-box{
        img{
          width: 100%;
          height: 100%;
        }
      }
      .discount-contract-img{
        img{
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  .bill-all{
    width: 100%;
    height: 500px;
    overflow-y: auto;
    li{
      width: 100%;
      padding: 20px;
      margin-bottom: 20px;
      border: 1px solid #DEDEDE;
      box-sizing: border-box;
      display: flex;
      align-items: center;
    }
    .bill-all-num{
      width: 25px;
      font-size: 18px;
      color: #333;
    }
    .bill-all-img{
      background: #F2F2F2;
      width: 224px;
      height: 119px;
      padding: 7px;
      margin-right: 15px;
      box-sizing: border-box;
      img{
        width: 100%;
        height: 100%;
      }
    }
    .bill-all-input{
      flex: 1;
      .el-form-item{
        width: 31%;
      }
    }
  }
  .bill-error{
    li{
      line-height: 30px;
      color: #C70009;
    }
  }
  /deep/ .el-upload--picture-card{
    position: relative;
    i{
      font-size: 28px;
      color: #8c939d;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-25%, -25%);
    }
  }
</style>
