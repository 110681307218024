<template>
  <div>
    <el-card>
      <el-descriptions :column="3">
        <el-descriptions-item label="操作类型">{{bizInfo.handlerType}}</el-descriptions-item>
        <el-descriptions-item label="操作编号">{{bizInfo.taskNo}}</el-descriptions-item>
        <el-descriptions-item label="任务状态">{{bizInfo.taskStatus}}</el-descriptions-item>
      </el-descriptions>
    </el-card>
    <!-- 质押信息 -->
    <el-card>
      <el-descriptions title="质押信息" :column="2">
        <el-descriptions-item label="出质人">{{pledgeInfoMore.debtor}}</el-descriptions-item>
        <el-descriptions-item label="出质人账号">{{pledgeInfoMore.debtorAccNo}}</el-descriptions-item>
        <el-descriptions-item label="开户银行">{{pledgeInfoMore.debtorAcctBankName}}</el-descriptions-item>
        <el-descriptions-item label="开户行行号">{{pledgeInfoMore.debtorAcctBankNo}}</el-descriptions-item>
        <el-descriptions-item label="质权人">{{pledgeInfoMore.creditor}}</el-descriptions-item>
        <el-descriptions-item label="质权人账号" v-if="pledgeInfoMore.creditorType == 'ENT'">{{pledgeInfoMore.creditorAcctNo}}</el-descriptions-item>
        <el-descriptions-item label="开户银行" v-if="pledgeInfoMore.creditorType == 'ENT'">{{pledgeInfoMore.creditorAcctBankName}}</el-descriptions-item>
        <el-descriptions-item label="开户行行号">{{pledgeInfoMore.creditorAcctBankNo}}</el-descriptions-item>
        <el-descriptions-item label="质押金额（元）">{{pledgeInfoMore.pledgeAmt}}</el-descriptions-item>
        <el-descriptions-item label="申请日期">{{pledgeInfoMore.applyDate}}</el-descriptions-item>
        <el-descriptions-item label="转让标记">{{pledgeInfoMore.transferFlag}}</el-descriptions-item>
        <el-descriptions-item label="备注">{{handleRemark}}</el-descriptions-item>
      </el-descriptions>
    </el-card>
    <!-- 票据基本信息：全质押 | 母票基本信息：拆分质押 -->
    <el-card>
      <WorkbenchPublicTitle :superInfoList="baseInfoList">
        <template v-slot:titleText>
          <h3>{{titleName}}</h3>
        </template>
      </WorkbenchPublicTitle>
      <el-table :data="baseInfoList" border class="mt20">
        <el-table-column prop="packageNo" label="票据包号"></el-table-column>
        <el-table-column prop="ticketRange" label="子票区间"></el-table-column>
        <el-table-column prop="packageSum" label="票据包金额"></el-table-column>
        <el-table-column prop="acceptor" label="承兑人"></el-table-column>
        <el-table-column prop="applyDate" label="出票日期"></el-table-column>
        <el-table-column prop="expireDate" label="票据到期日"></el-table-column>
        <el-table-column prop="ticketStatus" label="票据状态"></el-table-column>
      </el-table>
    </el-card>
    <!-- 子票基本信息 -->
    <el-card v-if="subBaseInfoList">
      <h3 class="mt30">子票基本信息</h3>
      <el-table :data="subBaseInfoList" border class="mt20">
        <el-table-column prop="subTicketInfo" label="子票信息"></el-table-column>
        <el-table-column prop="packageSum" label="子票包金额"></el-table-column>
        <el-table-column prop="transferFlag" label="转让标记"></el-table-column>
        <el-table-column prop="possessor" label="票据归属"></el-table-column>
      </el-table>
    </el-card>
    <!-- 70101010:质押申请-待质押复核 -->
    <div class="btn-group" v-if="bizInfo.handlerNodeCode == 70101010">
      <el-button type="primary" @click="handleShowMessage(5)">审核通过</el-button>
      <el-button type="warning"  @click="handleShowReject">关闭</el-button>
      <el-button @click="$router.go(-1)">返回</el-button>
    </div>
    <!-- 70301010:质押应答-待质押应答确认 -->
    <div class="btn-group" v-if="bizInfo.handlerNodeCode == 70301010">
      <el-button type="primary" @click="handleShowMessage(1)">同意应答</el-button>
      <el-button type="warning" @click="handleShowMessage(2)">拒绝应答</el-button>
      <el-button @click="$router.go(-1)">返回</el-button>
    </div>
    <!-- 成功弹框 -->
    <el-dialog :visible.sync="successDialogVisible"
		           width="50%"
		           :show-close="false"
		           :close-on-click-modal="false"
		           @close="$router.go(-1)">
			<div class="agree-dialog">
				<img src="~@/assets/img/ticket/cg.png" alt="">
				<h3>{{copyText}}</h3>
				<div class="copy-code">
					操作编号：<h4>{{copyCode}}</h4>
				</div>
				<el-button type="primary" @click="handleCopy">点击复制</el-button>
			</div>
			<span slot="footer" class="dialog-footer">
		    <el-button @click="successDialogVisible = false">关 闭</el-button>
		  </span>
		</el-dialog>
    <!-- 关闭意见 -->
    <el-dialog
						title="关闭意见"
						:visible.sync="rejectDialogVisible"
						width="40%"
						@close="rejectDialogClosed">
			<el-form :model="rejectForm"
			         :rules="rejectFormRules"
			         ref="rejectFormRef"
			         class="reject-form">
				<el-form-item prop="reject">
					<el-input v-model="rejectForm.reject" type="textarea" resize="none"></el-input>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="handleRefusalSubmit">确 定</el-button>
		    <el-button @click="rejectDialogVisible = false">关 闭</el-button>
		  </span>
		</el-dialog>
    <!--短信验证-->
		<el-dialog
						title="短信验证"
						:visible.sync="messageDialogVisible"
						:close-on-click-modal="false"
						width="40%"
						@close="messageDialogClosed">
			<el-form :model="messageForm"
			         :rules="messageFormRules"
			         ref="messageFormRef"
			         class="message-form">
				<el-form-item label="操作员手机号" prop="operatorMobile">
					{{mobile}}
				</el-form-item>
				<el-form-item label="短信验证码" prop="verifyCode">
					<el-input v-model="messageForm.verifyCode" class="verify-code"></el-input>
					<DelayButton />
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button type="primary" @click="handleMessageSubmit">提交</el-button>
		    <el-button @click="messageDialogVisible = false">关闭</el-button>
		  </span>
		</el-dialog>
  </div>
</template>

<script>
  // 票面预览/票面下载
  import WorkbenchPublicTitle from "@/views/Ticket/routerCpn/workbench/publicCpn/WorkbenchPublicTitle";
  // 倒计时按钮
  import DelayButton from "@/views/Ticket/childrenCpn/DelayButton";
  export default {
    name: 'PledgeOperate',
    props: ['bizInfo', 'baseInfoList', 'subBaseInfoList', 'pledgeInfoMore'],
    components: {
      WorkbenchPublicTitle,
      DelayButton
    },
    data(){
      return {
        approvalCode:'', // 审批代码 - 1：同意 2：拒绝 4：关闭 5：审核通过
        mobile: this.$store.state.getUser, //手机号
        copyCode: '', // 操作编号
        copyText: '', // 操作提示文字
        successDialogVisible: false, // 控制同意付款应答弹框的显示与隐藏
        rejectDialogVisible: false, // 控制拒绝付款应答弹框的显示与隐藏
        // 拒绝付款应答表单数据
        rejectForm: {
          reject: ''// 拒绝付款应答意见
        },
        // 拒绝付款应答表单验证规则
        rejectFormRules: {
          reject: [
            { required: true, message: '请输入关闭意见', trigger: 'blur' }
          ]
        },
        messageDialogVisible: false, // 控制短信弹框的显示与隐藏
        // 短信验证表单数据
        messageForm: {
          verifyCode: ''
        },
        // 短信验证表单验证规则
        messageFormRules:{
          verifyCode: [
            { required: true, message: '请输入验证码', trigger: 'blur' }
          ]
        },
      }
    },
    computed: {
      // 备注如果为空显示无
      handleRemark(){
        if(this.pledgeInfoMore.remark == "" || this.pledgeInfoMore.remark == null){
          return '无'
        }else{
          return this.pledgeInfoMore.remark
        }
      },
      // 票据基本信息：全质押 | 母票基本信息：拆分质押
      titleName(){
        if(this.subBaseInfoList){
          return '母票基本信息'
        }else {
          return '票据基本信息'
        }
      }
    },
    methods: {
      // 质押申请:待质押复核-审核通过 | 质押应答:待质押应答确认-同意应答|拒绝应答
      handleShowMessage(value){
        this.approvalCode = value;
        this.messageDialogVisible = true
      },
      // 质押申请:待质押复核 - 关闭
      handleShowReject(){
        this.approvalCode = 4
        this.rejectDialogVisible = true
      },
      // 质押申请 - 审核通过短信提交
      handleMessageSubmit(){
        this.$refs.messageFormRef.validate(async valid => {
          if(!valid) return;
          // 短信验证参数
          let verifyParams = {
            ticketId: this.bizInfo.ticketId,
            handlerTypeCode: this.bizInfo.handlerTypeCode,
            handlerNodeCode: this.bizInfo.handlerNodeCode,
            smsCode: this.messageForm.verifyCode
          }
          // 提交参数
          let submitParams = {
            ticketBizId: this.bizInfo.ticketId,
            remark: this.rejectForm.reject,
            approvalCode: this.approvalCode
          }
          // 短信验证
          const {data: res} = await this.$auth.post("/cpiaoju-ticket/workbench/task/todo/verifyCode", this.$qs.stringify(verifyParams));
          if (res.code === 200) {
            const {data: result} = await this.$auth.post('/cpiaoju-ticket/workbench/task/todo/pledge/approval', this.$qs.stringify(submitParams))
            if (result.code !== 200) return this.$message.error(result.msg)
            this.copyText = result.data.text;
            this.copyCode = result.data.handlerNo;
            this.successDialogVisible = true;
          }else {
            this.messageDialogVisible = false
            this.$message.error(res.msg);
          }
        })
      },
      // 短信验证 -- 关闭
      messageDialogClosed(){
        this.$refs.messageFormRef.resetFields()
      },
      // 质押申请 - 关闭提交
      handleRefusalSubmit(){
        this.$refs.rejectFormRef.validate(async valid => {
          if(!valid) return;
          // 提交参数
          let submitParams = {
            ticketBizId: this.bizInfo.ticketId,
            remark: this.rejectForm.reject,
            approvalCode: this.approvalCode
          }
          const {data: result} = await this.$auth.post('/cpiaoju-ticket/workbench/task/todo/pledge/approval', this.$qs.stringify(submitParams))
          if (result.code !== 200) return this.$message.error(result.msg)
          this.$router.push({name: 'taskWaitList'})
        })
      },
      // 质押申请 -- 关闭弹框
      rejectDialogClosed(){
        this.$refs.rejectFormRef.resetFields()
      },
       // 点击复制
       handleCopy(){
        this.$copyText(this.copyCode).then(res => {
          this.$message.success('复制成功')
          this.successDialogVisible = false
          return false;
        },err => {
          this.$message.error('复制失败')
          this.successDialogVisible = true
        })
      },
    }
  }
</script>

<style lang="less" scoped>
  /deep/ .el-descriptions__body{
    padding-left: 20px;
  }
  /*按钮*/
  /deep/ .btn-group{
    text-align: center;
    margin-top: 30px;
    .el-button{
      min-width: 110px;
      margin: 0 20px !important;
    }
  }
  /*同意收票弹框*/
  /deep/ .agree-dialog{
    display: flex;
    flex-direction: column;
    align-items: center;
    h3{
      margin-top: 20px;
    }
    .copy-code{
      margin-top: 15px;
      h4{
        display: inline-block;
        color: #C70009;
      }
    }
    .el-button{
      margin: 30px 24px 0;
    }
  }
  // 短信验证框
  .message-form{
    /deep/ .el-form-item__error{
      left: 90px !important;
    }
  }
</style>