<template>
	<div>
		<el-card>
      <el-descriptions :column="3">
        <el-descriptions-item label="操作类型">{{bizInfo.handlerType}}</el-descriptions-item>
        <el-descriptions-item label="操作编号">{{bizInfo.taskNo}}</el-descriptions-item>
        <el-descriptions-item label="任务状态">{{bizInfo.taskStatus}}</el-descriptions-item>
      </el-descriptions>
    </el-card>
    <!-- 票面信息 - 一期后加的 -->
    <BillFaceInfo 
      :maker="maker" 
      :acceptor="acceptor" 
      :taker="taker" 
      :ticketInfo="ticketInfo" 
      :ticketBaseInfoList="ticketBaseInfoList">
    </BillFaceInfo>
		<!--其他信息-->
		<el-card v-if="otherInfo">
      <el-descriptions title="其他信息" :column="3">
        <el-descriptions-item label="自动提示承兑">{{otherInfo.acceptance}}</el-descriptions-item>
        <el-descriptions-item label="自动提示收票">{{otherInfo.collection}}</el-descriptions-item>
        <!-- <el-descriptions-item label="备注">{{otherInfo.remark === null?'无': otherInfo.remark}}</el-descriptions-item> -->
        <el-descriptions-item label="备注">{{isRemark}}</el-descriptions-item>
      </el-descriptions>
    </el-card>
		<!--发票信息-->
		<el-card>
      <h3>发票信息</h3>
      <el-table :data="invoiceInfo.invoiceList" v-if="invoiceInfo" border stripe class="mt20" align="center">
        <el-table-column label="序号" type="index" width="50px"></el-table-column>
        <el-table-column label="发票代码" prop="invoiceCode"></el-table-column>
        <el-table-column label="发票号码" prop="invoiceNum"></el-table-column>
        <el-table-column label="开票日期" prop="invoiceDate"></el-table-column>
        <el-table-column label="价税合计（元）" prop="invoiceTotal">
          <template v-slot="scope">
            <span>{{parseFloat(scope.row.invoiceTotal).toFixed(2)}}</span>
          </template>
        </el-table-column>
        <el-table-column label="不含税金额（元）" prop="invoiceAmt">
          <template v-slot="scope">
            <span>{{parseFloat(scope.row.invoiceAmt).toFixed(2)}}</span>
          </template>
        </el-table-column>
        <el-table-column label="购买方" prop="payName"></el-table-column>
        <el-table-column label="销售方" prop="sellerName"></el-table-column>
        <el-table-column label="后六位校验码" prop="checkCode"></el-table-column>
        <el-table-column label="操作">
          <template v-slot="scope">
            <el-button type="primary" size="mini" @click="lookInvoice(scope.row)">查看</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="bill-all" v-if="invoiceInfo">
        <span>价税合计（总计）：{{invoiceInfo.taxTotal}}元</span>
        <span>发票张数：{{invoiceInfo.invoiceTotal}}张</span>
      </div>
    </el-card>
		<!--合同信息-->
		<el-card>
      <el-descriptions title="合同信息" :column="2" v-if="contractInfo">
        <el-descriptions-item label="合同编号">{{contractInfo.no}}</el-descriptions-item>
        <el-descriptions-item label="合同日期">{{contractInfo.date}}</el-descriptions-item>
        <el-descriptions-item label="合同金额（元）">{{contractInfo.money}}</el-descriptions-item>
        <el-descriptions-item label="合同信息分类">{{contractInfo.contractClass}}</el-descriptions-item>
        <el-descriptions-item label="贸易背景方式">{{contractInfo.way}}</el-descriptions-item>
        <el-descriptions-item label="合同附件">
          <a :href="contractInfo.accessoryUrl" target="_blank" class="accessory">{{contractInfo.accessoryName}}</a>
        </el-descriptions-item>
      </el-descriptions>
    </el-card>
		<!--查看发票对话框-->
		<el-dialog :visible.sync="invoiceDialogVisible">
			<img :src="invoiceUrl" class="invoiceImg" alt="">
			<span slot="footer" class="dialog-footer">
		    <el-button @click="invoiceDialogVisible = false">关 闭</el-button>
		  </span>
		</el-dialog>
	</div>
</template>

<script>
  // 票面信息 - 一期后加的
  import BillFaceInfo from '../../publicCpn/BillFaceInfo'
  export default {
    name: "TaskDetailCommon",
    props: ['bizInfo', 'otherInfo', 'invoiceInfo', 'contractInfo', 'maker', 'acceptor', 'taker', 'ticketInfo', 'ticketBaseInfoList'],
    components: {
      BillFaceInfo
    },
    computed: {
      // 判断其他信息 - 是否有备注
      isRemark(){
        if(this.otherInfo.remark){
          return this.otherInfo.remark
        }else {
          return '无'
        }
      }
    },
    data(){
      return {
        invoiceDialogVisible: false, // 控制查看发票对话框的显示与隐藏,
        invoiceUrl:'', // 查看发票图片地址
      }
    },
    methods: {
      // 查看发票
      lookInvoice(row){
        console.log(row)
        this.invoiceUrl = row.invoiceUrl
        this.invoiceDialogVisible = true
      }
    }
  }
</script>

<style lang="less" scoped>
	.el-descriptions{
		/deep/ .el-descriptions__header{
			margin-bottom: 10px;
		}
	}
	.task-face-value{
		width: 100%;
		height: 300px;
		padding: 5px;
		margin-top: 20px;
		border: 1px solid #DEDEDE;
		box-sizing: border-box;
		img{
			width: 100%;
			height: 100%;
		}
	}
	.bill-all span{
		display: inline-block;
		font-size: 14px;
		color: #666;
		margin-top: 10px;
	}
	.bill-all span:first-child{
		margin-right: 40px;
	}
	.invoiceImg{
		width: 100%;
		height: 100%;
	}
	.accessory{
		color: #C70009;
	}
</style>