<template>
	<div>
		<!--收票应答 - 待收票应答确认-->
		<div class="btn-group">
			<el-button type="primary" @click="handleAgreeBill">同意收票应答</el-button>
			<el-button type="warning" @click="handleRejectBill">拒绝收票应答</el-button>
			<el-button @click="$router.go(-1)">返回</el-button>
		</div>
		<!--短信验证-->
		<el-dialog
						title="短信验证"
						:visible.sync="messageDialogVisible"
						:close-on-click-modal="false"
						width="50%"
						@close="messageDialogClosed">
			<el-form :model="messageForm"
			         :rules="messageFormRules"
			         ref="messageFormRef"
			         class="message-form">
				<el-form-item label="操作员手机号" prop="operatorMobile">
					{{mobile}}
				</el-form-item>
				<el-form-item label="短信验证码" prop="verifyCode">
					<el-input v-model="messageForm.verifyCode" class="verify-code"></el-input>
					<DelayButton />
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button type="primary" @click="handleSubmit">提交</el-button>
		    <el-button @click="messageDialogVisible = false">关闭</el-button>
		  </span>
		</el-dialog>
		<!--拒绝短信验证-->
		<el-dialog
						title="短信验证"
						:visible.sync="messageRejectDialogVisible"
						:close-on-click-modal="false"
						width="50%"
						@close="messageDialogClosed">
			<el-form :model="messageRejectForm"
			         :rules="messageRejectFormRules"
			         ref="messageRejectFormRef"
			         class="message-form">
				<el-form-item label="操作员手机号" prop="operatorMobile">
					{{mobile}}
				</el-form-item>
				<el-form-item label="短信验证码" prop="verifyCode">
					<el-input v-model="messageRejectForm.verifyCode" class="verify-code"></el-input>
					<DelayButton />
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button type="primary" @click="handleRejectSubmit">提交</el-button>
		    <el-button @click="messageRejectDialogVisible = false">关闭</el-button>
		  </span>
		</el-dialog>
		<!--同意收票应答弹框-->
		<el-dialog :visible.sync="agreeDialogVisible"
		           width="40%"
							 :show-close="false"
		           :close-on-click-modal="false"
		           @close="$router.go(-1)">
			<div class="agree-dialog">
				<img src="~@/assets/img/ticket/cg.png" alt="">
				<h3>{{copyText}}</h3>
				<div class="copy-code">
					操作编号：<h4>{{copyCode}}</h4>
				</div>
				<el-button type="primary" @click="handleCopy">点击复制</el-button>
			</div>
			<span slot="footer" class="dialog-footer">
		    <el-button @click="agreeDialogVisible = false">关 闭</el-button>
		  </span>
		</el-dialog>
	</div>
</template>

<script>
	import DelayButton from "@/views/Ticket/childrenCpn/DelayButton";
  export default {
    name: "BackBillConfirm",
	  props: ['ticketId', 'handlerTypeCode', 'handlerNodeCode'],
    components: {
      DelayButton
    },
	  data(){
      return {
        mobile: '', //手机号
        copyCode: '', // 操作编号
	      copyText: '', // 操作提示文字
        agreeDialogVisible: false, // 控制同意弹框的显示与隐藏
        messageDialogVisible: false, // 控制短信弹框的显示与隐藏
        messageRejectDialogVisible: false, // 控制拒绝短信弹框的显示与隐藏
        // 短信验证表单数据
        messageForm: {
          verifyCode: ''
        },
        // 拒绝短信验证表单数据
        messageRejectForm: {
          verifyCode: ''
        },
        // 短信验证表单验证规则
        messageFormRules:{
          verifyCode: [
            { required: true, message: '请输入验证码', trigger: 'blur' }
          ]
        },
        // 短信验证表单验证规则
        messageRejectFormRules: {
          verifyCode: [
            { required: true, message: '请输入验证码', trigger: 'blur' }
          ]
        }
      }
	  },
	  methods: {
      //同意收票应答
      async handleAgreeBill(){
        //获取手机号
        const {data: res} = await this.$auth.get("/cpiaoju-ticket/common/mobile");
        if (res.code !== 200) return this.$message.error(res.msg);
        this.mobile = res.data.mobile
        this.messageDialogVisible = true
      },
      async handleRejectBill(){
        //获取手机号
        const {data: res} = await this.$auth.get("/cpiaoju-ticket/common/mobile");
        if (res.code !== 200) return this.$message.error(res.msg);
        this.mobile = res.data.mobile
        this.messageRejectDialogVisible = true
      },
      // 短信验证提交
      handleSubmit(){
        this.$refs.messageFormRef.validate(async valid => {
          if (!valid) return;
          // 短信验证参数
          let verifyParams = {
            ticketId: this.ticketId,
            handlerTypeCode: this.handlerTypeCode,
            handlerNodeCode: this.handlerNodeCode,
            smsCode: this.messageForm.verifyCode
          };
          //确认提交参数
          let submitParams = {
            ticketId: this.ticketId,
            handlerTypeCode: this.handlerTypeCode,
            handlerNodeCode: this.handlerNodeCode,
          }
          // 短信验证
          const {data: res} = await this.$auth.post("/cpiaoju-ticket/workbench/task/todo/verifyCode", this.$qs.stringify(verifyParams));
          if (res.code === 200) {
              //提示承兑申请-待提示承兑申请-确认提交
              const {data: result} = await this.$auth.post("/cpiaoju-ticket/workbench/task/todo/mt/takeResponding/confirm/agree", this.$qs.stringify(submitParams));
              if (result.code !== 200) return this.$message.error(result.msg)
              this.copyText = result.data.text;
              this.copyCode = result.data.handlerNo;
              this.messageDialogVisible = false;
              this.agreeDialogVisible = true
          }else {
            this.$message.error(res.msg);
            this.messageDialogVisible = false
          }
        })
      },
      // 短信验证关闭
      messageDialogClosed(){
        this.$refs.messageFormRef.resetFields()
      },
		  // 拒绝短信验证提交
      handleRejectSubmit(){
        this.$refs.messageRejectFormRef.validate(async valid => {
          if (!valid) return;
          // 短信验证参数
          let verifyParams = {
            ticketId: this.ticketId,
            handlerTypeCode: this.handlerTypeCode,
            handlerNodeCode: this.handlerNodeCode,
            smsCode: this.messageRejectForm.verifyCode
          };
          //确认提交参数
          let submitParams = {
            ticketId: this.ticketId,
            handlerTypeCode: this.handlerTypeCode,
            handlerNodeCode: this.handlerNodeCode,
          }
          // 短信验证
          const {data: res} = await this.$auth.post("/cpiaoju-ticket/workbench/task/todo/verifyCode", this.$qs.stringify(verifyParams));
          if (res.code === 200) {
            //提示承兑申请-待提示承兑申请-拒绝收票应答提交
            const {data: result} = await this.$auth.post("/cpiaoju-ticket/workbench/task/todo/mt/takeResponding/confirm/refuse", this.$qs.stringify(submitParams));
            if (result.code !== 200) return this.$message.error(result.msg)
            this.copyText = result.data.text;
            this.copyCode = result.data.handlerNo;
            this.messageRejectDialogVisible = false;
            this.agreeDialogVisible = true
          }else {
            this.$message.error(res.msg);
            this.messageRejectDialogVisible = true
          }
        })
      },
      // 拒绝收票提交
      // handleRejectSubmit(){
      //   this.$refs.rejectFormRef.validate(async valid => {
      //     if (!valid) return;
      //     let params = {
      //       ticketId: this.ticketId, // 登记申请id
      //       handlerTypeCode: this.handlerTypeCode, //操作类型code
      //       handlerNodeCode: this.handlerNodeCode, //节点状态code
      //       refuse: this.rejectForm.reject //驳回原因
      //     }
      //     const {data: res} = await this.$auth.post("/cpiaoju-ticket/workbench/task/todo/mt/takeResponding/confirm/refuse", this.$qs.stringify(params));
      //     if (res.code !== 200) return this.$message.error(res.msg);
      //     this.copyCode = res.data.handlerNo;
      //     this.copyText = res.data.text;
      //     this.rejectDialogVisible = false;
	    //     this.agreeDialogVisible = true;
      //   })
      // },
      // 点击复制
      handleCopy(){
        this.$copyText(this.copyCode).then(res => {
          this.$message.success('复制成功')
          this.agreeDialogVisible = false
          return false;
        },err => {
          this.$message.error('复制失败')
          this.agreeDialogVisible = true
        })
      }
	  }
  }
</script>

<style scoped>

</style>