<template>
  <div>
    <el-card>
      <el-descriptions :column="3">
        <el-descriptions-item label="操作类型">{{discountApply.handlerType}}</el-descriptions-item>
        <el-descriptions-item label="操作编号">{{discountApply.taskNo}}</el-descriptions-item>
        <el-descriptions-item label="任务状态">{{discountApply.taskStatus}}</el-descriptions-item>
      </el-descriptions>
    </el-card>
    <!--贴现信息-->
    <el-card>
      <h3 class="mt20">贴现信息</h3>
      <ul class="discount-info-list">
        <li>
          <span>贴现机构：</span>
          <em>{{discountApply.orgFullCnName}}</em>
        </li>
        <li>
          <span>贴现行号： </span>
          <em>{{discountApply.orgBankCode}}</em>
        </li>
        <li>
          <span>贴现金额（元）：</span>
          <em>{{discountApply.discountAmount}}</em>
        </li>
        <li>
          <span>申请日期：</span>
          <em>{{discountApply.applyTime}}</em>
        </li>
        <li>
          <span>贴现利率（%）：</span>
          <em>{{discountApply.discountRate}}</em>
        </li>
        <li>
          <span>到账金额（元）：</span>
          <em>{{discountApply.arrivalAmount}}</em>
        </li>
        <li>
          <span>平台服务费率（%）：</span>
          <em>{{discountApply.platformServiceRate}}</em>
        </li>
        <li>
          <span>应付服务费（元）：</span>
          <em>{{discountApply.payServiceCost}}</em>
        </li>
        <li>
          <span>收款账号：</span>
          <em>{{discountApply.recBankAccount}}</em>
        </li>
        <li>
          <span>收款账户行号：</span>
          <em>{{discountApply.recLegalNumber}}</em>
        </li>
        <li>
          <span>转让标记：</span>
          <em>{{discountApply.transferFlagName}}</em>
        </li>
        <li>
          <span>收款账户银行：</span>
          <em>{{discountApply.recBankName}}</em>
        </li>
        <li>
          <span>贴现种类：</span>
          <em>{{discountApply.discountType}}</em>
        </li>
        <li>
          <span>结算方式：</span>
          <em>{{discountApply.settlementModel === 'ST01' ? '线上' : '线下'}}</em>
        </li>
        <li>
          <span>付息方式：</span>
          <em>{{discountApply.payInterestType}}</em>
        </li>
        <li>
          <label>备注：</label>
          <em>{{discountApply.remark}}</em>
        </li>
      </ul>
    </el-card>
    <!--缴纳平台服务费-->
   <el-card>
     <h3>缴纳平台服务费</h3>
     <ul class="discount-info-list">
       <li>
         <span>账户名称：</span>
         <em>{{discountApply.lxAccountName}}</em>
       </li>
       <li>
         <span>银行全称：</span>
         <em>{{discountApply.lxBankName}}</em>
       </li>
       <li>
         <span>银行账户：</span>
         <em>{{discountApply.lxBankAccount}}</em>
       </li>
       <li>
         <span>大额行号：</span>
         <em>{{discountApply.lxLegalNumber}}</em>
       </li>
       <li>
         <label>缴纳凭证：</label>
         <el-upload
             action="http://117.122.218.200:11001/cpiaoju-ticket/front-company-register/uploadToOss"
             accept=".jpg,jpeg,.png"
             class="upload-paymentUrl"
             list-type="picture-card"
             name="multipartFile"
             :show-file-list="false"
             :on-success="(response, file, fileList)=> discountApply.payVouchersUrl = response.data">
           <img class="upload-img paymentUrl"
                v-if="discountApply.payVouchersUrl"
                :src="discountApply.payVouchersUrl"
                alt=""/>
           <i class="el-icon-plus" v-else></i>
         </el-upload>
       </li>
       <li>
         <label>缴纳时间：</label>
         <el-date-picker
             v-model="discountApply.payTime"
             type="date"
             format="yyyy-MM-dd"
             value-format="yyyy-MM-dd"
             placeholder="选择日期">
         </el-date-picker>
       </li>
     </ul>
   </el-card>
    <!--发票信息-->
    <el-card>
      <h3>发票信息</h3>
      <el-table :data="discountApply.invoiceList" border class="mt20">
        <el-table-column label="序号" type="index" width="50px"></el-table-column>
        <el-table-column label="发票代码" prop="invoiceCode" min-width="120px"></el-table-column>
        <el-table-column label="发票号码" prop="invoiceNum" min-width="100px"></el-table-column>
        <el-table-column label="开票日期" prop="invoiceDate" min-width="100px"></el-table-column>
        <el-table-column label="价税合计（元）" prop="taxIncludedAmount" min-width="120px">
          <template v-slot="scope">
            <span>{{parseFloat(scope.row.taxIncludedAmount).toFixed(2)}}</span>
          </template>
        </el-table-column>
        <el-table-column label="不含税金额（元）" prop="noTaxAmount" min-width="140px">
          <template v-slot="scope">
            <span>{{parseFloat(scope.row.noTaxAmount).toFixed(2)}}</span>
          </template>
        </el-table-column>
        <el-table-column label="购买方" prop="purchaserName" min-width="120px"></el-table-column>
        <el-table-column label="销售方" prop="sellerName" min-width="120px"></el-table-column>
        <el-table-column label="后六位校验码" prop="checkCode" min-width="120px"></el-table-column>
        <el-table-column label="操作" fixed="right" min-width="100px">
          <template v-slot="scope">
            <el-button type="primary" size="mini" @click="lookInvoice(scope.row)">查看</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="bill-all">
        <span>价税合计（总计）：{{discountApply.taxTotal}}元</span>
        <span>发票张数：{{discountApply.invoiceTotal}}张</span>
      </div>
    </el-card>
    <!--合同信息-->
    <el-card>
      <el-descriptions title="合同信息" :column="2">
        <el-descriptions-item label="合同编号">{{discountApply.contractNo}}</el-descriptions-item>
        <el-descriptions-item label="合同日期">{{discountApply.contractSignDate}}</el-descriptions-item>
        <el-descriptions-item label="合同金额（元）">{{discountApply.contractAmt}}</el-descriptions-item>
        <el-descriptions-item label="合同信息分类">
          <span v-if="discountApply.tradeInfoType == 1">合同</span>
          <span v-else>订单</span>
        </el-descriptions-item>
        <el-descriptions-item label="贸易背景方式">
          <span v-if="discountApply.tradeMode == '1'">货物贸易</span>
          <span v-else-if="discountApply.tradeMode == '2'">服务贸易</span>
          <span v-else-if="discountApply.tradeMode == '3'">货服贸易</span>
        </el-descriptions-item>
        <el-descriptions-item label="合同附件">
          <a :href="discountApply.tradeFileUrl" target="_blank" class="accessory">{{discountApply.fileName}}</a>
        </el-descriptions-item>
      </el-descriptions>
    </el-card>
    <!--母票基本信息-->
    <el-card>
      <WorkbenchPublicTitle :superInfoList="discountApply.discountTicketInfoVOList">
        <template v-slot:titleText>
          <h3>母票基本信息</h3>
        </template>
      </WorkbenchPublicTitle>
      <el-table :data="discountApply.discountTicketInfoVOList" border class="mt20">
        <el-table-column prop="packageNo" label="票据包号"></el-table-column>
        <el-table-column prop="packageSum" label="票据包金额"></el-table-column>
        <el-table-column prop="acceptorPersonName" label="承兑人"></el-table-column>
        <el-table-column prop="expireTime" label="票据到期日"></el-table-column>
        <el-table-column prop="ticketRange" label="子票区间"></el-table-column>
      </el-table>
    </el-card>
    <!--子票基本信息-->
    <el-card v-if="discountApply.ticketChildVOList !== null">
      <h3>子票基本信息</h3>
      <el-table :data="discountApply.ticketChildVOList" border class="mt20">
        <el-table-column prop="childTicketType" label="子票信息"></el-table-column>
        <el-table-column prop="ticketAffiliation" label="票据归属"></el-table-column>
        <el-table-column prop="childPackageSum" label="子票包金额"></el-table-column>
        <el-table-column prop="transferFlagName" label="转让标记"></el-table-column>
      </el-table>
    </el-card>
    <div class="btn-group">
      <el-button type="primary" @click="messageDialogVisible = true">审核通过</el-button>
      <el-button type="warning" @click="rejectDialogVisible = true">审核驳回</el-button>
      <el-button @click="$router.go(-1)">返回</el-button>
    </div>
    <!--短信验证-->
    <el-dialog
            title="短信验证"
            :visible.sync="messageDialogVisible"
            :close-on-click-modal="false"
            width="40%"
            @close="messageDialogClosed">
      <el-form :model="messageForm"
               :rules="messageFormRules"
               ref="messageFormRef"
               class="message-form">
        <el-form-item label="操作员手机号" prop="operatorMobile">
          {{mobile}}
        </el-form-item>
        <el-form-item label="短信验证码" prop="verifyCode">
          <el-input v-model="messageForm.verifyCode" class="verify-code"></el-input>
          <DelayButton />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
				<el-button type="primary" @click="handleThroughSubmit">提交</el-button>
		    <el-button @click="messageDialogVisible = false">关闭</el-button>
		  </span>
    </el-dialog>
    <!--应答弹框-->
    <el-dialog :visible.sync="answerDialogVisible"
               width="40%"
               :show-close="false"
               :close-on-click-modal="false"
               @close="$router.go(-1)">
      <div class="agree-dialog">
        <img src="~@/assets/img/ticket/cg.png" alt="">
        <h3>{{copyText}}</h3>
        <div class="copy-code">
          操作编号：<h4>{{copyCode}}</h4>
        </div>
        <el-button type="primary" @click="handleCopy">点击复制</el-button>
      </div>
      <span slot="footer" class="dialog-footer">
		    <el-button @click="answerDialogVisible = false">关 闭</el-button>
		  </span>
    </el-dialog>
    <!--驳回弹框-->
    <el-dialog
            title="驳回意见"
            :visible.sync="rejectDialogVisible"
            width="40%"
            @close="rejectDialogClosed">
      <el-form :model="rejectForm"
               :rules="rejectFormRules"
               ref="rejectFormRef"
               class="message-form">
        <el-form-item prop="reject">
          <el-input v-model="rejectForm.reject" type="textarea" resize="none"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
		    <el-button @click="rejectDialogVisible = false">关 闭</el-button>
		    <el-button type="primary" @click="handleRejectSubmit">确 定</el-button>
		  </span>
    </el-dialog>
    <!--查看发票对话框-->
    <el-dialog :visible.sync="invoiceDialogVisible">
      <img :src="invoiceUrl" class="invoiceImg" alt="">
      <span slot="footer" class="dialog-footer">
		    <el-button @click="invoiceDialogVisible = false">关 闭</el-button>
		  </span>
    </el-dialog>
  </div>
</template>

<script>
  /*倒计时按钮*/
  import DelayButton from "@/views/Ticket/childrenCpn/DelayButton";
  // 票面预览/票面下载
  import WorkbenchPublicTitle from "@/views/Ticket/routerCpn/workbench/publicCpn/WorkbenchPublicTitle";
  export default {
    name: "DiscountReview",
    props: ['discountApply', 'handlerTypeCode', 'handlerNodeCode'],
    components: {
      DelayButton,
      WorkbenchPublicTitle
    },
    data(){
      return {
        invoiceDialogVisible: false, // 控制查看发票对话框的显示与隐藏,
        invoiceUrl:'', // 查看发票图片地址
        mobile: this.$store.state.getUser, //手机号
        copyCode: '', // 操作编号
        copyText: '', // 操作提示文字
        messageDialogVisible: false, // 控制短信弹框的显示与隐藏
        // 短信验证表单数据
        messageForm: {
          verifyCode: ''
        },
        // 短信验证表单验证规则
        messageFormRules:{
          verifyCode: [
            { required: true, message: '请输入验证码', trigger: 'blur' }
          ]
        },
        answerDialogVisible: false, // 控制应答弹框的显示与隐藏
        rejectDialogVisible: false, // 控制审核驳回弹框的显示与隐藏
        // 审核驳回表单数据
        rejectForm: {
          reject: ''// 审核意见
        },
        // 审核驳回表单验证规则
        rejectFormRules: {
          reject: [
            { required: true, message: '请输入驳回意见', trigger: 'blur' }
          ]
        }
      }
    },
    methods: {
      // 审核通过提交
      handleThroughSubmit(){
        this.$refs.messageFormRef.validate(async valid => {
          if (!valid) return;
          let params = {
            id: this.discountApply.ticketBizId,
            handlerType: this.handlerTypeCode,
            verifyCode: this.messageForm.verifyCode,
            payTime: this.discountApply.payTime,
            payVouchersUrl: this.discountApply.payVouchersUrl
          }
          const {data: res} = await this.$auth.get('/cpiaoju-ticket/discount/verifyCode', {params})
          if (res.code === 200){
            this.messageDialogVisible = false
            this.answerDialogVisible = true
            this.copyText = '复核提交成功'
            this.copyCode = res.data
          }else {
            this.messageDialogVisible = true
            this.$message.error(res.msg)
          }

        })
      },
      // 审核通过短信验证关闭
      messageDialogClosed(){
        this.$refs.messageFormRef.resetFields()
      },
      // 审核驳回提交
      handleRejectSubmit(){
        this.$refs.rejectFormRef.validate(async valid => {
          if(!valid) return;
          let params = {
            ticketBizId: this.discountApply.ticketBizId,
            handlerType: this.handlerTypeCode,
            handlerNode: this.handlerNodeCode,
            operResult: 0,
            opinion: this.rejectForm.reject
          }
          const {data: res} = await this.$auth.post('/cpiaoju-ticket/discount/audit', this.$qs.stringify(params))
          if (res.code !== 200) return this.$message.error(res.msg)
          this.$router.push('/ticket/taskWaitList')
        })
      },
      // 审核驳回验证关闭
      rejectDialogClosed(){
        this.$refs.rejectFormRef.resetFields()
      },
      // 发票信息列表 - 查看
      lookInvoice(row){
        console.log(row)
        this.invoiceUrl = row.invoiceImgOssUrl
        this.invoiceDialogVisible = true
      },
      // 点击复制
      handleCopy(){
        this.$copyText(this.copyCode).then(res => {
          this.$message.success('复制成功')
          this.agreeDialogVisible = false
          return false;
        },err => {
          this.$message.error('复制失败')
          this.agreeDialogVisible = true
        })
      }
    }
  }
</script>

<style lang="less" scoped>
  /*标题*/
  .title{
    h3{
      display: inline-block;
      margin-right: 10px;
    }
  }
  /*按钮*/
  /deep/ .btn-group{
    text-align: center;
    margin-top: 30px;
    /deep/ .el-button{
      min-width: 110px;
      margin-right: 20px !important;
    }
  }
  /*贴现信息*/
  .discount-info-list{
    display: flex;
    flex-wrap: wrap;
    li{
      width: 50%;
      margin-top: 20px;
      > span, label{
        display: inline-block;
        width: 150px;
        text-align: right;
      }
      span::before{
        display: inline-block;
        content: "*";
        color: #c70009;
        margin-right: 3px;
      }
    }
    /*缴纳凭证*/
    /deep/ .upload-paymentUrl{
      display: inline-block;
      .paymentUrl{
        width: 100%;
        height: 100%;
      }
    }
  }
  /*发票信息*/
  .bill-all{
    padding: 15px 20px;
    border: 1px solid #EBEEF5;
    border-top: none;
    span{
      display: inline-block;
      font-size: 14px;
      color: #666;
      margin-top: 10px;
    }
    span:first-child{
      margin-right: 40px;
    }
  }
  /*应答弹框*/
  /deep/ .agree-dialog{
    display: flex;
    flex-direction: column;
    align-items: center;
    h3{
      margin-top: 20px;
    }
    .copy-code{
      margin-top: 15px;
      h4{
        display: inline-block;
        color: #C70009;
      }
    }
    .el-button{
      margin: 30px 24px 0;
    }
  }
  .accessory{
    color: #c70009;
  }
  .invoiceImg{
    width: 100%;
    height: 100%;
  }
  /*合同信息*/
  /deep/.el-descriptions-item__label:not(.is-bordered-label){
    width: 120px;
    text-align: right;
  }
</style>