<template>
	<div>
    <el-card>
      <el-descriptions :column="3" class="mt30">
        <el-descriptions-item label="操作类型">{{bizInfo.handlerType}}</el-descriptions-item>
        <el-descriptions-item label="操作编号">{{bizInfo.taskNo}}</el-descriptions-item>
        <el-descriptions-item label="任务状态">{{bizInfo.taskStatus}}</el-descriptions-item>
      </el-descriptions>
    </el-card>
    <el-table :data="logInfoList" border class="mt30">
      <el-table-column type="index" label="序号" width="100px"></el-table-column>
      <el-table-column label="操作时间" prop="operTime"></el-table-column>
      <el-table-column label="当前节点" prop="handlerNode"></el-table-column>
      <el-table-column label="操作者" prop="operName"></el-table-column>
      <el-table-column label="操作结果" prop="operResult"></el-table-column>
      <el-table-column label="意见" prop="opinion">
        <template v-slot="scope">
          <span>{{scope.row.opinion !== null ? scope.row.opinion : '无'}}</span>
        </template>
      </el-table-column>
    </el-table>
	</div>

</template>

<script>
  export default {
    name: "TaskDetailFlow",
    props: ['bizInfo', 'logInfoList']
  }
</script>

<style scoped>

</style>