<template>
	<div>
		<!--出票登记 - 待修改-->
		<div class="btn-group">
			<el-button type="primary" @click="handleSubmit">确认提交</el-button>
			<el-button type="warning" @click="handleClosed">关闭</el-button>
			<el-button @click="$router.go(-1)">返回</el-button>
		</div>
		<!--收票申请成功-->
		<el-dialog :visible.sync="successDialogVisible"
		           width="40%"
		           :show-close="false"
		           :close-on-click-modal="false"
		           @close="$router.go(-1)">
			<div class="agree-dialog">
				<img src="~@/assets/img/ticket/cg.png" alt="">
				<h3>{{copyText}}</h3>
				<div class="copy-code">
					操作编号：<h4>{{copyCode}}</h4>
				</div>
				<el-button type="primary" @click="handleCopy">点击复制</el-button>
			</div>
			<span slot="footer" class="dialog-footer">
		    <el-button @click="successDialogVisible = false">关 闭</el-button>
		  </span>
		</el-dialog>
		<!--错误发票弹框-->
		<el-dialog
						title="提示"
						:visible.sync="billErrorDialogVisible"
						width="50%">
			<ul class="bill-error">
				<li v-for="(item,index) in errorList" :key="index">{{item}}</li>
			</ul>
			<span slot="footer" class="dialog-footer">
		    <el-button @click="billErrorDialogVisible = false">取 消</el-button>
		    <el-button type="primary" @click="billErrorDialogVisible = false">确 定</el-button>
		  </span>
		</el-dialog>
	</div>
</template>

<script>
  export default {
    name: "OutBillModify",
    props: ['ticketId', 'handlerTypeCode', 'handlerNodeCode', 'invoiceList', 'contractForm','total','tradeAmt'],
	  data(){
      return {
        copyCode: '', // 操作编号
        copyText: '', // 操作提示文字
        successDialogVisible: false, // 控制收票成功弹框的显示与隐藏
        errorList: [],// 验证失败发票的列表
        billErrorDialogVisible: false, // 控制验证发票失败弹窗的显示与隐藏
      }
	  },
	  methods: {
      // 关闭
      async handleClosed(){
        let params = {
          ticketId: this.ticketId,
          handlerTypeCode: this.handlerTypeCode,
          handlerNodeCode: this.handlerNodeCode
        }
        const {data: res} = await this.$auth.post("/cpiaoju-ticket/workbench/task/todo/mt/registerApply/modify/close", this.$qs.stringify(params));
        if(res.code !== 200) return this.$message.error(res.msg)
	      this.$router.go(-1)
      },
		  //提交
      handleSubmit(){
        this.$refs.formRef.validate(async valid => {
          let params = {//验证发票的参数
            registId: this.ticketId,
            invoiceList: JSON.stringify(this.invoiceList),
            ...this.contractForm
          };
          let params2 = {// 确认提交的参数
            ticketId: this.ticketId,
            handlerTypeCode: this.handlerTypeCode,
            handlerNodeCode: this.handlerNodeCode,
            tradeInvoiceList: JSON.stringify(this.invoiceList),
            ...this.contractForm
          };
          if (!valid) return;
          if (Number(this.tradeAmt) > Number(this.contractForm.contractAmt)){
            return this.$message.error('合同金额要大于包票金额')
          }else if (Number(this.tradeAmt > Number(this.total))){
            return this.$message.error('发票金额要大于包票金额')
          }
          // 第一步先调取出票登记-贸易信息（保存）接口
          const {data: res} = await this.$auth.post("/cpiaoju-ticket/ticket/tradeSave", this.$qs.stringify(params))
	        if(res.code == 200){
            console.log('success', res)
		        const {data: res} = await this.$auth.post("/cpiaoju-ticket/workbench/task/todo/mt/registerApply/modify/submit", this.$qs.stringify(params2));
            console.log('####', res)
            if(res.code !== 200) return this.$message.error(res.msg);
            this.copyCode = res.data.handlerNo;
            this.copyText = res.data.text;
		        this.successDialogVisible = true
	        }else{
            this.billErrorDialogVisible = true
		        this.errorList = res.data
	        }
        })
      },
      // 点击复制
      handleCopy(){
        this.$copyText(this.copyCode).then(res => {
          this.$message.success('复制成功')
          this.successDialogVisible = false
          return false;
        },err => {
          this.$message.error('复制失败')
          this.successDialogVisible = true
        })
      },
	  }
  }
</script>

<style lang="less" scoped>
.btn-group{
	text-align: center;
	margin-top: 50px;
	.el-button{
		min-width: 110px;
		margin-right: 20px !important;
	}
}
</style>