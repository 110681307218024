<template>
  <div>
    <!-- 票据信息 -->
		<el-card v-if="ticketInfo !== null">
      <el-descriptions title="票据信息" :column="2">
        <el-descriptions-item label="票据类别">{{ticketInfo.ticketType}}</el-descriptions-item>
        <el-descriptions-item label="票据包金额（元）">{{ticketInfo.packageSum}}</el-descriptions-item>
        <el-descriptions-item label="出票日期">{{ticketInfo.applyDate}}</el-descriptions-item>
        <el-descriptions-item label="票据到期日">{{ticketInfo.expireDate}}</el-descriptions-item>
        <el-descriptions-item label="转让标记">{{ticketInfo.transferFlag}}</el-descriptions-item>
      </el-descriptions>
    </el-card>
    <!-- 出票人 -->
		<el-card v-if="maker !== null">
      <el-descriptions title="出票人" :column="2">
        <el-descriptions-item label="出票人">{{maker.compName}}</el-descriptions-item>
        <el-descriptions-item label="出票人账号">{{maker.acctNo}}</el-descriptions-item>
        <el-descriptions-item label="开户行">{{maker.acctBankName}}</el-descriptions-item>
        <el-descriptions-item label="开户行行号">{{maker.acctBankNo}}</el-descriptions-item>
      </el-descriptions>
    </el-card>
    <!-- 承兑人 -->
		<el-card v-if="acceptor !== null">
      <el-descriptions title="承兑人" :column="2">
        <el-descriptions-item label="承兑人">{{acceptor.compName}}</el-descriptions-item>
        <el-descriptions-item label="承兑人账号">{{acceptor.acctNo}}</el-descriptions-item>
        <el-descriptions-item label="开户行">{{acceptor.acctBankName}}</el-descriptions-item>
        <el-descriptions-item label="开户行行号">{{acceptor.acctBankNo}}</el-descriptions-item>
      </el-descriptions>
    </el-card>
    <!-- 收票人 -->
		<el-card v-if="taker !== null">
      <el-descriptions title="收票人" :column="2">
        <el-descriptions-item label="收票人">{{taker.compName}}</el-descriptions-item>
        <el-descriptions-item label="收票人账号">{{taker.acctNo}}</el-descriptions-item>
        <el-descriptions-item label="开户行">{{taker.acctBankName}}</el-descriptions-item>
        <el-descriptions-item label="开户行行号">{{taker.acctBankNo}}</el-descriptions-item>
      </el-descriptions>
    </el-card>
    <!-- 票据基本信息 -->
    <el-card v-if="ticketBaseInfoList !== null">
      <template>
        <WorkbenchPublicTitle :superInfoList="ticketBaseInfoList" style="margin-top: 0">
          <template v-slot:titleText>
            <h3>票据基本信息</h3>
          </template>
        </WorkbenchPublicTitle>
        <el-table :data="ticketBaseInfoList" border class="mt20">
          <el-table-column prop="packageNo" label="票据包号"></el-table-column>
          <el-table-column prop="packageSum" label="票据包金额"></el-table-column>
          <el-table-column prop="acceptor" label="承兑人"></el-table-column>
          <el-table-column prop="expireDate" label="票据到期日"></el-table-column>
          <el-table-column prop="ticketRange" label="子票区间"></el-table-column>
        </el-table>
      </template>
    </el-card>
  </div>
</template>

<script>
import WorkbenchPublicTitle from './WorkbenchPublicTitle'
export default {
  name: 'BillFaceInfo',
  props: ['maker', 'acceptor', 'taker', 'ticketInfo', 'ticketBaseInfoList'],
  components: {
      WorkbenchPublicTitle
    },
}
</script>

<style>

</style>