<template>
	<div>
		<!--承兑应答 - 待修改-->
		<div class="btn-group">
			<el-button type="primary" @click="handleAgree">同意承兑应答</el-button>
			<el-button type="warning" @click="rejectDialogVisible = true">拒绝承兑应答</el-button>
			<el-button @click="$router.go(-1)">返回</el-button>
		</div>
		<!--同意承兑应答弹框-->
		<el-dialog :visible.sync="agreeDialogVisible"
		           width="40%"
		           :show-close="false"
		           :close-on-click-modal="false"
		           @close="$router.go(-1)">
			<div class="agree-dialog">
				<img src="~@/assets/img/ticket/cg.png" alt="">
				<h3>{{copyText}}</h3>
				<div class="copy-code">
					操作编号：<h4>{{copyCode}}</h4>
				</div>
				<el-button type="primary" @click="handleCopy">点击复制</el-button>
			</div>
			<span slot="footer" class="dialog-footer">
		    <el-button @click="agreeDialogVisible = false">关 闭</el-button>
		  </span>
		</el-dialog>
		<!--拒绝承兑应答-->
		<el-dialog
						title="驳回意见"
						:visible.sync="rejectDialogVisible"
						width="40%"
						@close="rejectDialogClosed">
			<el-form :model="rejectForm"
			         :rules="rejectFormRules"
			         ref="rejectFormRef"
			         class="message-form">
				<el-form-item prop="reject">
					<el-input v-model="rejectForm.reject" type="textarea" resize="none"></el-input>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
		    <el-button @click="rejectDialogVisible = false">关 闭</el-button>
		    <el-button type="primary" @click="handleRejectSubmit">确 定</el-button>
		  </span>
		</el-dialog>
	</div>
</template>

<script>
  export default {
    name: "AcceptModify",
    props: ['ticketId', 'handlerTypeCode', 'handlerNodeCode'],
	  data(){
      return {
        copyCode: '', // 操作编号
        copyText: '', // 操作提示文字
        agreeDialogVisible: false, // 控制同意承兑应答显示与隐藏
        rejectDialogVisible: false, // 控制审核驳回弹框的显示与隐藏
        // 拒绝承兑应答表单数据
        rejectForm: {
          reject: ''// 拒绝理由
        },
        // 拒绝承兑应答表单验证规则
        rejectFormRules: {
          reject: [
            { required: true, message: '请输入拒绝理由', trigger: 'blur' }
          ]
        },
      }
	  },
	  methods: {
      // 同意承兑应答
      async handleAgree(){
        this.agreeDialogVisible = true;
        let params = {
          ticketId: this.ticketId, //登记申请id
          handlerTypeCode: this.handlerTypeCode, //操作类型code
          handlerNodeCode: this.handlerNodeCode  //节点状态code
        }
        // 承兑应答-待修改-同意承兑应答
        const {data: res} = await this.$auth.post("/cpiaoju-ticket/workbench/task/todo/mt/acceptResponse/modify/agreeResp", this.$qs.stringify(params))
        if(res.code !== 200) return this.$message.error(res.msg);
        this.copyCode = res.data.handlerNo || '';
        this.copyText = res.data.text || '';
      },
      // 拒绝承兑应答关闭
      rejectDialogClosed(){
        this.$refs.rejectFormRef.resetFields()
      },
		  // 拒绝承兑应答提交
      handleRejectSubmit(){
        this.$refs.rejectFormRef.validate(async valid => {
          if (!valid) return;
          let params = {
            ticketId: this.ticketId,
            handlerTypeCode: this.handlerTypeCode,
            handlerNodeCode: this.handlerNodeCode,
            refuse: this.rejectForm.reject
          }
          const{data: res} = await this.$auth.post("/cpiaoju-ticket/workbench/task/todo/mt/acceptResponse/modify/refuseResp", this.$qs.stringify(params));
          if (res.code !== 200) return this.$message.error(res.msg)
          this.copyCode = res.data.handlerNo;
          this.copyText = res.data.text;
          this.rejectDialogVisible= false
	        this.agreeDialogVisible = true
        })
      },
      // 点击复制
      handleCopy(){
        this.$copyText(this.copyCode).then(res => {
          this.$message.success('复制成功')
          this.agreeDialogVisible = false
          return false;
        },err => {
          this.$message.error('复制失败')
          this.agreeDialogVisible = true
        })
      },
	  }
  }
</script>

<style scoped>

</style>