<template>
  <div class="mt30">
    <el-descriptions :column="3">
      <el-descriptions-item label="操作类型">{{bizInfo.handlerType}}</el-descriptions-item>
      <el-descriptions-item label="操作编号">{{bizInfo.taskNo}}</el-descriptions-item>
      <el-descriptions-item label="任务状态">{{bizInfo.taskStatus}}</el-descriptions-item>
    </el-descriptions>
    <!-- 票面信息 - 一期后加的 -->
    <BillFaceInfo 
      :maker="maker" 
      :acceptor="acceptor" 
      :taker="taker" 
      :ticketInfo="ticketInfo" 
      :ticketBaseInfoList="ticketBaseInfoList">
    </BillFaceInfo>
    <!--其他信息-->
    <el-card v-if="otherInfo">
      <el-descriptions title="其他信息" :column="3" style="margin-top: 0">
        <el-descriptions-item label="自动提示承兑">{{otherInfo.acceptance}}</el-descriptions-item>
        <el-descriptions-item label="自动提示收票">{{otherInfo.collection}}</el-descriptions-item>
        <el-descriptions-item label="备注">{{isRemark}}</el-descriptions-item>
      </el-descriptions>
    </el-card>
    <div class="file-bill mt30">
      <!--上传发票头部-->
      <div class="file-bill-top">
        <div class="file-bill-top-left">
          <span>发票信息</span>
          <span>请上传文件格式为png、jpeg、jpg格式的文件，单个文件大小在2M以内</span>
        </div>
        <div class="file-bill-top-right">
          <el-button v-if="invoiceList.length !== 0" @click="billDialogVisible = true">全部发票</el-button>
          <el-upload
                  class="upload-demo"
                  action="http://117.122.218.200:11001/cpiaoju-ticket/ticket/invoiceOcr"
                  :show-file-list="false"
                  :data="{id: this.id}"
                  name="files"
                  multiple
                  accept=".png,.jpeg,.jpg"
                  :before-upload="handleBeforeUpload"
                  :on-success="handleSuccess">
            <el-button type="primary">批量上传发票</el-button>
          </el-upload>
        </div>
      </div>
      <!--上传发票展示区域-->
      <div class="file-bill-box">
        <div class="file-bill-left">
          <div class="file-big-img">
            <img :src="discernForm.invoiceUrl"
                 v-if="discernForm.invoiceUrl"
                 alt="">
            <div class="file-big-num"
                 v-show="isInvoiceList">
              <span>{{invoiceCurrentIndex + 1}}</span>/{{invoiceList.length}}
            </div>
          </div>
          <div class="file-total-money">
            价税合计：<span>{{total.toFixed(2)}}</span>元
          </div>
          <!--轮播图-->
          <div class="carousel-box">
            <Carousel :list="invoiceList"
                      :id="this.id"
                      class="carousel"
                      v-if="invoiceList.length != 0"
                      @handleCarouselClick="handleCarouselClick"
                      @carouselRemove="carouselRemove"/>
          </div>
        </div>
        <div class="file-bill-right">
          <el-form :model="discernForm"
                   ref="formRef1"
                   :rules="rulesForm"
                   label-width="120px">
            <el-form-item label="校验结果：">
              <span class="verify-btn pass" v-if="discernForm.status =='1'">识别成功</span>
              <span class="verify-btn failure" v-if="discernForm.status =='2'">识别失败</span>
              <span class="verify-btn failure" v-if="discernForm.status =='3'">重复发票</span>
            </el-form-item>
            <el-form-item label="购方名称：" prop="payName">
              <el-input v-model="discernForm.payName"></el-input>
            </el-form-item>
            <el-form-item label="销方名称：" prop="sellerName">
              <el-input v-model="discernForm.sellerName"></el-input>
            </el-form-item>
            <el-form-item label="发票代码：" prop="invoiceCode">
              <el-input v-model="discernForm.invoiceCode"></el-input>
            </el-form-item>
            <el-form-item label="发票号码：" prop="invoiceNum">
              <el-input v-model="discernForm.invoiceNum"></el-input>
            </el-form-item>
            <el-form-item label="开票日期：" prop="invoiceDate">
              <el-date-picker
                      v-model="discernForm.invoiceDate"
                      type="date"
                      format="yyyy-MM-dd"
                      value-format="yyyy-MM-dd"
                      placeholder="选择日期">
              </el-date-picker>
            </el-form-item>
            <el-form-item label="价税合计：" prop="invoiceTotal">
              <el-input v-model="discernForm.invoiceTotal"
                        style="width: 90%!important;margin-right: 5px;"></el-input>
              元
            </el-form-item>
            <el-form-item label="不含税金额：" prop="invoiceAmt">
              <el-input v-model="discernForm.invoiceAmt"
                        style="width: 90%!important;margin-right: 5px;"></el-input>
              元
            </el-form-item>
            <el-form-item label="后6位校验码：" prop="checkCode" v-if="discernForm.invoiceType !== '专用发票'">
              <el-input v-model="discernForm.checkCode"></el-input>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
    <!--合同信息-->
    <div class="contract-info mt30">
      <h3>合同信息</h3>
      <el-form :model="contractForm"
               :rules="rulesForm"
               ref="formRef"
               label-width="140px"
               class="mt20">
        <el-form-item label="合同编号：" prop="no">
          <el-input v-model="contractForm.no" 
                    placeholder="32位以内的数字或字母组成"
                    maxlength="32"></el-input>
        </el-form-item>
        <el-form-item label="合同日期：" prop="date">
          <el-date-picker v-model="contractForm.date"
                          type="date"
                          format="yyyy-MM-dd"
                          value-format="yyyy-MM-dd"
                          placeholder="选择日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="合同金额（元）：" prop="money">
          <el-input v-model="contractForm.money"></el-input>
        </el-form-item>
        <el-form-item label="合同信息分类：" prop="contractClass">
          <el-select placeholder="请选择" v-model="contractForm.contractClass">
            <el-option label="合同" value="1"></el-option>
            <el-option label="订单" value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="贸易背景方式：" prop="way">
          <el-select placeholder="请选择" v-model="contractForm.way">
            <el-option label="货物贸易" value="1"></el-option>
            <el-option label="服务贸易" value="2"></el-option>
            <el-option label="货服贸易" value="3"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="合同附件：" prop="fileUrl">
          <el-upload
                  action="http://117.122.218.200:11001/cpiaoju-ticket/ticket/uploadFile"
                  :show-file-list="false"
                  accept=".jpg,jpeg,.bmp,.png,.pdf,.zip,.rar"
                  class="upload-demo"
                  list-type="picture-card"
                  name="file"
                  :before-upload="handleContractBefore"
                  :on-success="handleContractSuccess">
            <img class="upload-img aa"
                 v-if="flag"
                 :src="pdfImg"
                 alt="">
            <img class="upload-img bb"
                 :src="contractForm.accessoryUrl"
                 v-else
                 alt=""/>
            <i class="el-icon-plus" v-else></i>
            <div slot="tip" class="el-upload__tip">
              提示说明：<br/>
              1、可上传购销合同或订单证明文件<br/>
              2、仅允许上传类型为jpg、jpeg、bmp、png、pdf、zip、rar，文件名称不超过50位，并且不大于1.4MB的文件
            </div>
          </el-upload>
        </el-form-item>
      </el-form>
    </div>
    <!--按钮-->
    <div class="btn-group">
      <el-button type="primary" @click="handleSubmit">确认提交</el-button>
      <el-button type="warning" @click="handleClosed">关闭</el-button>
      <el-button @click="$router.go(-1)">返回</el-button>
    </div>
    <!--全部发票-->
    <el-dialog title="全部发票"
               :visible.sync="billDialogVisible"
               width="90%">
      <ul class="bill-all">
        <li v-for="(item, index) in invoiceList">
          <div class="bill-all-num">{{index + 1}}</div>
          <div class="bill-all-img">
            <img :src="item.invoiceUrl" alt="">
          </div>
          <div class="bill-all-input">
            <el-form :inline="true"
                     label-width="120px">
              <el-form-item label="校验结果：">
                <span class="verify-btn pass" v-if="item.status =='1'">识别成功</span>
                <span class="verify-btn failure" v-if="item.status =='2'">识别失败</span>
                <span class="verify-btn failure" v-if="item.status =='3'">重复发票</span>
              </el-form-item>
              <el-form-item label="购方名称：">
                <el-input v-model="item.payName"></el-input>
              </el-form-item>
              <el-form-item label="销方名称：">
                <el-input v-model="item.sellerName"></el-input>
              </el-form-item>
              <el-form-item label="发票代码：">
                <el-input v-model="item.invoiceCode"></el-input>
              </el-form-item>
              <el-form-item label="发票号码：">
                <el-input v-model="item.invoiceNum"></el-input>
              </el-form-item>
              <el-form-item label="开票日期：">
                <el-date-picker
                        v-model="item.invoiceDate"
                        type="date"
                        format="yyyy-MM-dd"
                        value-format="yyyy-MM-dd"
                        placeholder="选择日期">
                </el-date-picker>
              </el-form-item>
              <el-form-item label="价税合计：">
                <el-input v-model="item.invoiceTotal"
                          style="width: 89%!important;margin-right: 5px;"></el-input>
                元
              </el-form-item>
              <el-form-item label="不含税金额：">
                <el-input v-model="item.invoiceAmt"
                          style="width: 89%!important;margin-right: 5px;"></el-input>
                元
              </el-form-item>
              <el-form-item label="后6位校验码：">
                <el-input v-model="item.checkCode" v-show="item.invoiceType !== '专用发票'"></el-input>
              </el-form-item>
            </el-form>
          </div>
        </li>
      </ul>
    </el-dialog>
    <!--收票申请成功-->
    <el-dialog :visible.sync="successDialogVisible"
               width="40%"
               :show-close="false"
               :close-on-click-modal="false"
               @close="$router.go(-1)">
      <div class="agree-dialog">
        <img src="~@/assets/img/ticket/cg.png" alt="">
        <h3>{{copyText}}</h3>
        <div class="copy-code">
          操作编号：<h4>{{copyCode}}</h4>
        </div>
        <el-button type="primary" @click="handleCopy">点击复制</el-button>
      </div>
      <span slot="footer" class="dialog-footer">
		    <el-button @click="successDialogVisible = false">关 闭</el-button>
		  </span>
    </el-dialog>
    <!--错误发票弹框-->
    <el-dialog
            title="提示"
            :visible.sync="billErrorDialogVisible"
            width="50%">
      <ul class="bill-error">
        <li v-for="(item,index) in errorList" :key="index">{{item}}</li>
      </ul>
      <span slot="footer" class="dialog-footer">
		    <el-button @click="billErrorDialogVisible = false">取 消</el-button>
		    <el-button type="primary" @click="billErrorDialogVisible = false">确 定</el-button>
		  </span>
    </el-dialog>
  </div>
</template>

<script>
  /*轮播图*/
  import Carousel from '@/views/Ticket/childrenCpn/Carousel'
  // 票面信息 - 一期后加的
  import BillFaceInfo from '../../publicCpn/BillFaceInfo'
  export default {
    name: "SpecialOne",
    props: ['ticketId', 'id', 'handlerTypeCode', 'handlerNodeCode', 'bizInfo', 'otherInfo', 'invoiceInfo', 'contractInfo', 'maker', 'acceptor', 'taker', 'ticketInfo', 'ticketBaseInfoList'],
    components: {
      Carousel,
      BillFaceInfo
    },
    mounted() {
      this.invoiceList = this.invoiceInfo.invoiceList
      this.discernForm = this.invoiceList[0]
      this.contractForm = {...this.contractInfo}
      var accessoryUrl = this.contractInfo.accessoryUrl;
      if (accessoryUrl.substring(accessoryUrl.length - 3, accessoryUrl.length) === 'pdf' || accessoryUrl.substring(accessoryUrl.length - 3, accessoryUrl.length) === 'zip' || accessoryUrl.substring(accessoryUrl.length - 3, accessoryUrl.length) === 'rar') {
        this.flag = true
      } else {
        this.flag = false
      }
    },
    // updated() {
    //   this.discernForm = this.invoiceList[0]
    // },
    data() {
      // 验证合同编号的正则表达式
      const regContractNo = /^[A-Za-z0-9]+$/;
      var checkContractNo = (rule, value, callback) => {
        if (regContractNo.test(value)) {
          callback();
        }
        callback(new Error("合同编号需要32位数字与字母组合"));
      };
      return {
        billDialogVisible: false,//控制全部发票的显示与隐藏
        invoiceList: [],// 上传后返回的识别数据
        invoiceCurrentIndex: 0, //发票列表当前数
        // 发票信息 - 识别出来的信息
        discernForm: {
          status: '',  //1识别成功2识别失败
          invoiceUrl: '', //发票图片路径
          payName: '', //购买方名称
          sellerName: '', //销售方名称
          invoiceNum: '', //发票号码
          invoiceCode: '', //发票代码
          invoiceDate: '', //开票日期
          invoiceTotal: '', //价税合计
          invoiceAmt: '', //不含税金额
          checkCode: '', //后六位校验码 专票此项必须为空
        }, // 发票信息 - 识别出来的信息
        rulesForm: {
          payName: [
            {required: true, message: '请输入购方名称', trigger: 'blur'}
          ],
          sellerName: [
            {required: true, message: '请输入销方名称', trigger: 'blur'}
          ],
          invoiceCode: [
            {required: true, message: '请输入发票代码', trigger: 'blur'}
          ],
          invoiceNum: [
            {required: true, message: '请输入发票号码', trigger: 'blur'}
          ],
          invoiceDate: [
            {required: true, message: '请输入开票日期', trigger: 'blur'}
          ],
          invoiceTotal: [
            {required: true, message: '请输入价税合计', trigger: 'blur'}
          ],
          invoiceAmt: [
            {required: true, message: '请输入不含税金额', trigger: 'blur'}
          ],
          checkCode: [
            {required: true, message: '请输入后6位校验码', trigger: 'blur'}
          ],
          no: [
            { required: true, message: '请输入合同编号', trigger: 'blur' },
            { min: 0, max: 32, message: "只能输入32位字符", trigger: "blur", },
            { validator: checkContractNo, trigger: "blur" }
          ],
          date: [
            {required: true, message: '请输入合同日期', trigger: 'blur'}
          ],
          money: [
            {required: true, message: '请输入合同金额', trigger: 'blur'}
          ],
          contractClass: [
            {required: true, message: '请选择合同信息分类', trigger: 'blur'}
          ],
          way: [
            {required: true, message: '请选择贸易背景方式', trigger: 'blur'}
          ],
        },
        // 合同信息
        contractForm: {
          no: '', //合同编号
          date: '', //合同日期
          money: '', //合同金额
          contractClass: '', //合同信息分类（1 合同 2 订单）
          way: '', //贸易背景方式（1-货物贸易 2-服务贸易 3-货服贸易）
          accessoryUrl: '', //合同上传后返回的路径
          pdfImg: '', // 合同上传pdf返回的路径
        },
        copyCode: '', // 操作编号
        copyText: '', // 操作提示文字
        successDialogVisible: false, // 控制收票成功弹框的显示与隐藏
        errorList: [],// 验证失败发票的列表
        billErrorDialogVisible: false, // 控制验证发票失败弹窗的显示与隐藏
        pdfImg: 'https://cpiaoju.oss-cn-beijing.aliyuncs.com/lxgw/gpxt/upload_image/pdf.png', //合同附件上传pdf时展示的固定图片
        flag: false, // 判断页面获取数据时合同附件是否为pdf
      }
    },
    computed: {
      total() {
        return this.invoiceList.reduce((preValue, item) => {
          return preValue + Number(item.invoiceTotal)
        }, 0)
      },
      isInvoiceList() {
        if (this.invoiceList.length == 0) {
          return false
        } else {
          return true
        }
      },
      // 判断其他信息 - 是否有备注
      isRemark(){
        if(this.otherInfo.remark){
          return this.otherInfo.remark
        }else {
          return '无'
        }
      }
    },
    methods: {
      // 监听上传发票之前的钩子
      handleBeforeUpload(file) {
        let fileNameLen = file.name.substring(0, file.name.lastIndexOf('.'));
        let imgSize = Number(file.size / 1024 / 1024);
        if (imgSize > 2) {
          this.$message.error('上传文件大小不能超过2M')
          return false;
        }
        return imgSize
      },
      // 监听上传发票成功回调
      handleSuccess(res, file, fileList) {
        this.invoiceList.push(res.data)
        if (this.invoiceList.length > 0) {
          this.discernForm = this.invoiceList[0]
        }
      },
      // 监听删除当前发票
      carouselRemove(item){
        this.invoiceList = item.invoiceList
        if(!item.invoiceList.length){
          this.invoiceCurrentIndex = -1
          this.discernForm.invoiceUrl = ''
          this.discernForm.status = ''
          this.discernForm.payName = ''
          this.discernForm.sellerName = ''
          this.discernForm.invoiceCode = ''
          this.discernForm.invoiceNum = ''
          this.discernForm.invoiceDate = ''
          this.discernForm.invoiceTotal = ''
          this.discernForm.invoiceAmt = ''
          this.discernForm.checkCode = ''
        }else{
          this.invoiceCurrentIndex = 0
          this.discernForm = this.invoiceList[0]
        }
      },
      // 监听获取当前发票识别出的信息
      handleCarouselClick(value) {
        //显示当前激活的识别信息
        this.discernForm = this.invoiceList[value]
        //当前激活的个数
        this.invoiceCurrentIndex = value
      },
      // 监听上传合同前回调
      handleContractBefore(file) {
        let fileNameLen = file.name.substring(0, file.name.lastIndexOf('.'));
        let imgSize = Number(file.size / 1024 / 1024);
        if (fileNameLen.length > 50) {
          this.$message.error('上传文件名称不能超过50位');
          return false;
        }
        if (imgSize > 1.4) {
          this.$message.error('上传文件大小不能超过1.4M');
          return false;
        }
        return imgSize || fileNameLen
      },
      // 监听上传合同附件成功
      handleContractSuccess(res, file, fileList) {
        this.contractForm.accessoryUrl = '';
        this.contractForm.pdfImg = '';
        if (res.pdfImg) {
          this.contractForm.accessoryUrl = res.pdfImg;
          this.contractForm.pdfImg = res.pdfImg
          this.flag = true
        } else {
          this.contractForm.accessoryUrl = res.fileUrl
          this.flag = false
        }
      },
      // 关闭
      async handleClosed() {
        let params = {
          ticketId: this.ticketId,
          handlerTypeCode: this.handlerTypeCode,
          handlerNodeCode: this.handlerNodeCode
        }
        const {data: res} = await this.$auth.post("/cpiaoju-ticket/workbench/task/todo/mt/registerApply/modify/close", this.$qs.stringify(params));
        if (res.code !== 200) return this.$message.error(res.msg)
        this.$router.go(-1)
      },
      //提交
      handleSubmit() {
        this.$refs['formRef'].validate(valid => {
          if(!valid) return;
          this.$refs['formRef1'].validate(async valid => {
            if (!valid) return;
            let params2 = {
              ticketId: this.ticketId,
              handlerTypeCode: this.handlerTypeCode,
              handlerNodeCode: this.handlerNodeCode,
              tradeInvoiceList: JSON.stringify(this.invoiceList),
              ...this.contractForm
            };
            if (!valid) return;
            if (Number(this.tradeAmt) > Number(this.contractForm.contractAmt)) {
              return this.$message.error('合同金额要大于票据包金额')
            } else if (Number(this.tradeAmt > Number(this.total))) {
              return this.$message.error('发票金额要大于票据包金额')
            }
            if (!this.contractForm.accessoryUrl && !this.contractForm.pdfImg) {
              return this.$message.error('合同附件必传')
            }
            const {data: res} = await this.$auth.post("/cpiaoju-ticket/workbench/task/todo/mt/registerApply/modify/submit", this.$qs.stringify(params2));
            if (res.code == 200) {
              this.copyCode = res.data.handlerNo;
              this.copyText = res.data.text;
              this.successDialogVisible = true
            } else {
              this.billErrorDialogVisible = true
              this.errorList = res.data.list
            }
          })
        })
      },
      // 点击复制
      handleCopy() {
        this.$copyText(this.copyCode).then(res => {
          this.$message.success('复制成功')
          this.successDialogVisible = false
          return false;
        }, err => {
          this.$message.error('复制失败')
          this.successDialogVisible = true
        })
      },
    }
  }
</script>

<style lang="less" scoped>
  .el-descriptions {
    margin-top: 30px;

    /deep/ .el-descriptions__header {
      margin-bottom: 10px;
    }
  }

  .task-face-value {
    width: 100%;
    height: 300px;
    padding: 5px;
    margin-top: 20px;
    border: 1px solid #DEDEDE;
    box-sizing: border-box;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .file-bill {
    .file-bill-top {
      display: flex;
      justify-content: space-between;

      .file-bill-top-left {
        line-height: 40px;

        span {
          display: inline-block;
        }

        span:first-child {
          font-size: 16px;
          color: #24262B;
          margin-right: 10px;
        }

        span:nth-of-type(2) {
          font-size: 12px;
          color: #666;

          i {
            color: #D6000F;
          }
        }
      }

      .file-bill-top-right {
        display: flex;

        .verify-btn {
          display: inline-block;
          width: 110px;
          height: 40px;
          line-height: 40px;
          text-align: center;
        }

        .el-button {
          margin: 0 10px;
        }
      }
    }

    .file-bill-box {
      display: flex;
      width: 100%;
      height: 518px;
      margin-top: 25px;
      border: 1px solid #DEDEDE;
      box-sizing: border-box;

      .file-bill-left {
        width: 60%;
        border-right: 1px solid #dedede;
        box-sizing: border-box;

        .carousel-box {
          height: 90px;
          padding: 0 15px;
          box-sizing: border-box;
        }

        .file-big-img {
          background: #F2F2F2;
          width: 100%;
          height: 362px;
          padding: 0 50px;
          box-sizing: border-box;
          position: relative;

          .file-big-num {
            position: absolute;
            top: 10px;
            right: 10px;

            span {
              font-size: 20px;
            }
          }

          img {
            width: 100%;
            height: 100%;
          }
        }

        .file-total-money {
          color: #666666;
          padding-left: 15px;
          margin-top: 15px;
          box-sizing: border-box;

          span {
            color: #D6000F;
            font-weight: 700;
          }
        }
      }

      .file-bill-right {
        width: 40%;
        padding-right: 20px;
        padding-top: 15px;
        box-sizing: border-box;

        .verify-btn {
          display: inline-block;
          width: 100px;
          height: 40px;
          line-height: 40px;
          text-align: center;
          border-radius: 4px;
          box-sizing: border-box;
        }

        .pass {
          background: #F6FFED;
          color: #53C41C;
          border: 1px solid #53C41C;
        }

        .failure {
          background: #FFE6E5;
          color: #F00036;
          border: 1px solid #F00036;
        }

        .el-form-item {
          margin-bottom: 15px !important;
        }

        .el-date-editor.el-input {
          width: 100%;
        }
      }
    }
  }

  .contract-info {
    .el-upload__tip {
      line-height: 1.5;
    }

    .el-select {
      width: 100%;
    }

    /deep/ .el-date-editor.el-input, .el-date-editor.el-input__inner {
      width: 100%;
    }
  }

  .btn {
    text-align: center;
    margin: 50px auto 20px;
  }

  .upload-img {
    width: 148px;
    height: 148px;
  }

  .bill-all {
    width: 100%;
    height: 500px;
    overflow-y: auto;

    li {
      width: 100%;
      padding: 20px;
      margin-bottom: 20px;
      border: 1px solid #DEDEDE;
      box-sizing: border-box;
      display: flex;
      align-items: center;
    }

    .bill-all-num {
      width: 25px;
      font-size: 18px;
      color: #333;
    }

    .bill-all-img {
      background: #F2F2F2;
      width: 224px;
      height: 119px;
      padding: 7px;
      margin-right: 15px;
      box-sizing: border-box;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .bill-all-input {
      flex: 1;
      flex: 1;

      .el-form-item {
        width: 31%;
      }
    }
  }

  .btn-group {
    text-align: center;
    margin-top: 50px;

    .el-button {
      min-width: 110px;
      margin-right: 20px !important;
    }
  }

  .bill-error {
    li {
      line-height: 30px;
      color: #C70009;
    }
  }

  /*同意收票弹框*/
  /deep/ .agree-dialog {
    display: flex;
    flex-direction: column;
    align-items: center;

    h3 {
      margin-top: 20px;
    }

    .copy-code {
      margin-top: 15px;

      h4 {
        display: inline-block;
        color: #C70009;
      }
    }

    .el-button {
      margin: 30px 24px 0;
    }
  }
</style>