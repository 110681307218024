<template>
  <div>
    <el-descriptions :column="3">
      <el-descriptions-item label="操作类型">{{bizInfo.handlerType}}</el-descriptions-item>
      <el-descriptions-item label="操作编号">{{bizInfo.taskNo}}</el-descriptions-item>
      <el-descriptions-item label="任务状态">{{bizInfo.taskStatus}}</el-descriptions-item>
    </el-descriptions>
    <!--背书信息-->
    <el-descriptions title="背书信息" :column="2" class="mt30">
      <el-descriptions-item label="背书人">{{baseInfo.compName}}</el-descriptions-item>
      <el-descriptions-item label="背书人账号">{{baseInfo.acctNo}}</el-descriptions-item>
      <el-descriptions-item label="开户行">{{baseInfo.acctBankName}}</el-descriptions-item>
      <el-descriptions-item label="开户行行号">{{baseInfo.acctBankNo}}</el-descriptions-item>
      <el-descriptions-item label="背书金额">{{baseInfo.packageSum}}</el-descriptions-item>
      <el-descriptions-item label="申请日期">{{baseInfo.applyDate}}</el-descriptions-item>
      <el-descriptions-item label="转让标记">{{baseInfo.transferFlag}}</el-descriptions-item>
      <el-descriptions-item label="备注">{{baseInfo.remark===''? '无' : baseInfo.remark}}</el-descriptions-item>
    </el-descriptions>
    <div class="tips">温馨提示：当转让标记为不得转让时，应答签收后，票据无法继续流转和贴现，只能持有到期。请谨慎应答。</div>
    <WorkbenchPublicTitle :superInfoList="superInfoList"></WorkbenchPublicTitle>
    <el-table :data="superInfoList" border class="mt20">
      <el-table-column prop="packageNo" label="票据包号"></el-table-column>
      <el-table-column prop="packageSum" label="票据包金额"></el-table-column>
      <el-table-column prop="acceptor" label="承兑人"></el-table-column>
      <el-table-column prop="expireDate" label="票据到期日"></el-table-column>
      <el-table-column prop="ticketRange" label="子票区间"></el-table-column>
    </el-table>
    <div class="btn-group">
      <el-button type="primary" @click="handleAgree">同意应答</el-button>
      <el-button type="warning" @click="handleReject">拒绝应答</el-button>
      <el-button @click="$router.go(-1)">返回</el-button>
    </div>
    <!--同意应答短信验证-->
    <el-dialog
            title="短信验证"
            :visible.sync="messageDialogVisible"
            :close-on-click-modal="false"
            width="40%"
            @close="AgreeDialogClosed">
      <el-form :model="messageForm"
               :rules="messageFormRules"
               ref="messageFormRef"
               class="message-form">
        <el-form-item label="操作员手机号" prop="operatorMobile">
          {{mobile}}
        </el-form-item>
        <el-form-item label="短信验证码" prop="verifyCode">
          <el-input v-model="messageForm.verifyCode" class="verify-code"></el-input>
          <DelayButton />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
				<el-button type="primary" v-if="flag" @click="handleAgreeSubmit">提交</el-button>
        <el-button type="primary" v-if="!flag" @click="handleRejectSubmit">提交</el-button>
		    <el-button @click="messageDialogVisible = false">关闭</el-button>
		  </span>
    </el-dialog>
    <!--应答弹框-->
    <el-dialog :visible.sync="answerDialogVisible"
               width="40%"
               :show-close="false"
               :close-on-click-modal="false"
               @close="$router.go(-1)">
      <div class="agree-dialog">
        <img src="~@/assets/img/ticket/cg.png" alt="">
        <h3>{{copyText}}</h3>
        <div class="copy-code">
          操作编号：<h4>{{copyCode}}</h4>
        </div>
        <el-button type="primary" @click="handleCopy">点击复制</el-button>
      </div>
      <span slot="footer" class="dialog-footer">
		    <el-button @click="answerDialogVisible = false">关 闭</el-button>
		  </span>
    </el-dialog>
  </div>
</template>

<script>
  /*倒计时按钮*/
  import DelayButton from "@/views/Ticket/childrenCpn/DelayButton";
  // 票面预览/票面下载
  import WorkbenchPublicTitle from "@/views/Ticket/routerCpn/workbench/publicCpn/WorkbenchPublicTitle";
  export default {
    name: "EndorseAnswer",
    props:['bizInfo', 'baseInfo', 'superInfoList', 'subInfoList'],
    components: {
      DelayButton,
      WorkbenchPublicTitle
    },
    data(){
      return {
        flag: null, // 控制短信弹框提交按钮
        mobile: this.$store.state.getUser, // 手机号
        copyCode: '', // 操作编号
        copyText: '', // 操作提示文字
        messageDialogVisible: false, // 控制同意应答短信弹框的显示与隐藏
        // 同意应答短信验证表单数据
        messageForm: {
          verifyCode: ''
        },
        // 同意应答短信验证表单验证规则
        messageFormRules:{
          verifyCode: [
            { required: true, message: '请输入验证码', trigger: 'blur' }
          ]
        },
        answerDialogVisible: false, // 控制应答弹框的显示与隐藏
      }
    },
    methods: {
      // 同意应答
      handleAgree(){
        this.messageDialogVisible = true
        this.flag = true
      },
      // 拒绝应答
      handleReject(){
        this.messageDialogVisible = true
        this.flag = false
      },
      // 同意应答短信验证关闭
      AgreeDialogClosed(){
        this.$refs.messageFormRef.resetFields()
      },
      // 同意应答提交
      handleAgreeSubmit(){
        this.$refs.messageFormRef.validate(async valid => {
          if (!valid) return;
          // 短信验证参数
          let verifyParams = {
            ticketId: this.bizInfo.ticketId,
            handlerTypeCode: this.bizInfo.handlerTypeCode,
            handlerNodeCode: this.bizInfo.handlerNodeCode,
            smsCode: this.messageForm.verifyCode
          };
          // 同意应答参数
          let params = {
            ticketId: this.bizInfo.ticketId,
            handlerTypeCode: this.bizInfo.handlerTypeCode,
            handlerNodeCode: this.bizInfo.handlerNodeCode
          }
          const {data: res} = await this.$auth.post("/cpiaoju-ticket/workbench/task/todo/verifyCode", this.$qs.stringify(verifyParams));
          if (res.code === 200) {
            const {data: result} = await this.$auth.post('/cpiaoju-ticket/workbench/task/todo/bs/resp/confirm/agree', this.$qs.stringify(params))
            if (result.code !== 200) return this.$message.error(result.msg)
            this.copyText = result.data.text;
            this.copyCode = result.data.handlerNo;
            this.answerDialogVisible = true;
          }else {
            this.$message.error(res.msg);
          }

        })
      },
      // 拒绝应答提交
      handleRejectSubmit(){
        this.$refs.messageFormRef.validate(async valid => {
          if (!valid) return;
          // 短信验证参数
          let verifyParams = {
            ticketId: this.bizInfo.ticketId,
            handlerTypeCode: this.bizInfo.handlerTypeCode,
            handlerNodeCode: this.bizInfo.handlerNodeCode,
            smsCode: this.messageForm.verifyCode
          };
          // 拒绝应答
          let params = {
            ticketId: this.bizInfo.ticketId,
            handlerTypeCode: this.bizInfo.handlerTypeCode,
            handlerNodeCode: this.bizInfo.handlerNodeCode,
            refuse: ''
          }
          // 短信验证
          const {data: res} = await this.$auth.post("/cpiaoju-ticket/workbench/task/todo/verifyCode", this.$qs.stringify(verifyParams));
          if (res.code === 200) {
            const {data: result} = await this.$auth.post('/cpiaoju-ticket/workbench/task/todo/bs/resp/confirm/refuse', this.$qs.stringify(params))
            if (result.code !== 200) return this.$message.error(result.msg)
            this.copyText = result.data.text;
            this.copyCode = result.data.handlerNo
            this.answerDialogVisible = true
          }else {
            this.$message.error(res.msg);
            this.messageDialogVisible = true
          }

        })
      },
      // 点击复制
      handleCopy(){
        this.$copyText(this.copyCode).then(res => {
          this.$message.success('复制成功')
          this.messageDialogVisible = false
          return false;
        },err => {
          this.$message.error('复制失败')
          this.messageDialogVisible = true
        })
      }
    }
  }
</script>

<style lang="less" scoped>
  /*标题*/
  .title{
    h3{
      display: inline-block;
      margin-right: 10px;
    }
  }
  /*提示*/
  .tips{
    color: #C70009;
  }
  /*按钮*/
  /deep/ .btn-group{
    text-align: center;
    margin-top: 30px;
    /deep/ .el-button{
      min-width: 110px;
      margin-right: 20px !important;
    }
  }
  /*应答弹框*/
  /deep/ .agree-dialog{
    display: flex;
    flex-direction: column;
    align-items: center;
    h3{
      margin-top: 20px;
    }
    .copy-code{
      margin-top: 15px;
      h4{
        display: inline-block;
        color: #C70009;
      }
    }
    .el-button{
      margin: 30px 24px 0;
    }
  }
</style>