<template>
	<div>
		<!--面包屑-->
		<el-breadcrumb separator-class="el-icon-arrow-right">
			<el-breadcrumb-item>工作台</el-breadcrumb-item>
			<el-breadcrumb-item>待办任务</el-breadcrumb-item>
			<el-breadcrumb-item>任务详情</el-breadcrumb-item>
		</el-breadcrumb>
		<!--tab切换-->
		<el-tabs v-model="activeName" type="border-card">
			<el-tab-pane label="任务详情" name="first">
				<CommonOne v-if="type == 'CP_CHECK'"
                   :bizInfo="bizInfo"
                   :otherInfo="otherInfo"
                   :invoiceInfo="invoiceInfo"
                   :contractInfo="contractInfo"
                   :maker="maker"
                   :acceptor="acceptor"
                   :taker="taker"
                   :ticketInfo="ticketInfo"
                   :ticketBaseInfoList="ticketBaseInfoList"/>
				<SpecialOne v-if="type == 'CP_MODIFY'"
										:id="id"
                    :tradeAmt="tradeAmt"
				            :ticketId = "bizInfo.ticketId"
				            :handlerTypeCode="bizInfo.handlerTypeCode"
				            :handlerNodeCode="bizInfo.handlerNodeCode"
                    :bizInfo="bizInfo"
                    :otherInfo="otherInfo"
                    :invoiceInfo="invoiceInfo"
                    :contractInfo="contractInfo"
                    :maker="maker"
                    :acceptor="acceptor"
                    :taker="taker"
                    :ticketInfo="ticketInfo"
                    :ticketBaseInfoList="ticketBaseInfoList"/>
				<!--背书申请/待背书复核	-->
				<EndorseApply v-if="type == 'BS_APPLY'"
                      :bizInfo="bizInfo"
                      :baseInfo="baseInfo"
                      :superInfoList="superInfoList"
                      :subInfoList="subInfoList"/>
				<!--背书应答/待背书应答确认	-->
				<EndorseAnswer v-if="type == 'BS_RESP'"
                       :bizInfo="bizInfo"
                       :baseInfo="baseInfo"
                       :superInfoList="superInfoList"
                       :subInfoList="subInfoList"/>
				<!--贴现申请/待贴现复核	-->
				<DiscountReview v-if="type == 'TX_CHECK'"
                        :handlerTypeCode="this.searchForm.handlerTypeCode"
                        :handlerNodeCode="this.searchForm.handlerNodeCode"
                        :discountApply="discountApply"></DiscountReview>
				<!--贴现申请/待修改	-->
				<DiscountModify v-if="type == 'TX_MODIFY'"
                        :discountModify="discountApply"/>
				<!-- 提示付款应答 -->
				<Payment v-if="type == 'FK_CHECK'"
								 :bizInfo="bizInfo"
								 :payResp="payResp"
								 :baseInfoList="baseInfoList"
								 :payeeInfo="payeeInfo"/>
				<!-- 保证申请: 待保证复核 - 保证应答：	待保证应答确认/待保证应答复核/待修改  -->
				<GuaranteeApply v-if="type == 'BZ_CHECK'"
												:bizInfo="bizInfo"
												:ensureResp="ensureResp"
												:baseInfoList="baseInfoList"
												:ensureInfo="ensureInfo"/>
				<!-- 质押申请:ZY_APPLY - 质押应答:ZY_RESP -->
				<PledgeOperate v-if="type == 'ZY_APPLY' || type == 'ZY_RESP'"
											 :bizInfo="bizInfo"
											 :baseInfoList="baseInfoList"
											 :subBaseInfoList="subBaseInfoList"
											 :pledgeInfoMore = "pledgeInfoMore || {}"/>
				<!-- 质押解除申请:JC_APPLY - 质押解除应答:JC_RESP -->
				<PledgeRecallOperate v-if="type == 'JC_APPLY' || type == 'JC_RESP'"
													   :bizInfo="bizInfo"
											 			 :baseInfoList="baseInfoList"
											 			 :subBaseInfoList="subBaseInfoList"
														 :dePledgeInfoMore="dePledgeInfoMore || {}">

				</PledgeRecallOperate>
			</el-tab-pane>
			<el-tab-pane label="任务流程" name="second">
				<CommonTwo :bizInfo="bizInfo" :logInfoList="logInfoList"></CommonTwo>
			</el-tab-pane>
		</el-tabs>
		<div class="btn">
			<!--出票登记 - 待复核-->
			<OutBillReview v-if="bizInfo.handlerNodeCode == 10101010"
			               :ticketId = "bizInfo.ticketId"
			               :handlerTypeCode="bizInfo.handlerTypeCode"
			               :handlerNodeCode="bizInfo.handlerNodeCode"/>
			<!--承兑申请 - 待提示承兑申请-->
			<AcceptApply v-else-if="bizInfo.handlerNodeCode == 10201010"
			             :ticketId = "bizInfo.ticketId"
			             :handlerTypeCode="bizInfo.handlerTypeCode"
			             :handlerNodeCode="bizInfo.handlerNodeCode"/>
			<!--承兑应答 - 确认-->
			<AcceptConfirm v-else-if="bizInfo.handlerNodeCode == 10401010"
			               :ticketId = "bizInfo.ticketId"
			               :handlerTypeCode="bizInfo.handlerTypeCode"
			               :handlerNodeCode="bizInfo.handlerNodeCode"/>
			<!--承兑应答 - 复核-->
			<AcceptReview v-else-if="bizInfo.handlerNodeCode == 10401020"
			              :acceptResp="acceptResp"
			              :ticketId = "bizInfo.ticketId"
			              :handlerTypeCode="bizInfo.handlerTypeCode"
			              :handlerNodeCode="bizInfo.handlerNodeCode"/>
			<!--承兑应答 - 待修改-->
			<AcceptModify v-else-if="bizInfo.handlerNodeCode == 10401050"
			              :ticketId = "bizInfo.ticketId"
			              :handlerTypeCode="bizInfo.handlerTypeCode"
			              :handlerNodeCode="bizInfo.handlerNodeCode"/>
			<!--收票应答 - 待收票应答确认-->
			<BackBillConfirm v-else-if="bizInfo.handlerNodeCode == 10701010"
			                 :ticketId = "bizInfo.ticketId"
			                 :handlerTypeCode="bizInfo.handlerTypeCode"
			                 :handlerNodeCode="bizInfo.handlerNodeCode" />
			<!--收票申请 - 待提示收票申请-->
			<BackBillApply v-else-if="bizInfo.handlerNodeCode == 10501010"
			               :ticketId = "bizInfo.ticketId"
			               :handlerTypeCode="bizInfo.handlerTypeCode"
			               :handlerNodeCode="bizInfo.handlerNodeCode"/>
		</div>
	</div>
</template>

<script>
	// 任务详情 - 普通的
	import CommonOne from "./childCpn/CommonOne";
	// 任务流程
	import CommonTwo from "./childCpn/CommonTwo";
	// 任务详情 - 待修改
	import SpecialOne from "./childCpn/SpecialOne";
	// 任务详情 - 背书申请 - 待背书复核
	import EndorseApply from "./second/EndorseApply";
	// 任务详情 - 背书应答 - 待背书应答确认
	import EndorseAnswer from "./second/EndorseAnswer";
	// 任务详情 - 贴现申请- 待贴现复核
	import DiscountReview from "./second/DiscountReview";
	// 任务详情 - 贴现申请- 待修改
	import DiscountModify from "./second/DiscountModify";
	// 任务详情 - 提示付款应答 - 待提示付款应答确认/待提示付款应答复核/待修改
	import Payment from './third/Payment.vue'
	// 任务详情 - 保证申请: 待保证复核 - 保证应答：	待保证应答确认/待保证应答复核/待修改 
	import GuaranteeApply from './four/GuaranteeApply'
	// 任务详情 - 质押申请/待质押复核 - 质押应答/待质押应答确认
	import PledgeOperate from './four/PledgeOperate'
	// 任务详情 - 质押解除申请/待质押解除复核 - 质押解除应答/待质押解除应答确认
	import PledgeRecallOperate from './four/PledgeRecallOperate'
	//出票登记 - 待复核
	import OutBillReview from "./childCpn/btn/OutBillReview";
  // 出票登记 - 待修改
	import OutBillModify from "./childCpn/btn/OutBillModify";
  // 承兑申请 - 待提示承兑申请
	import AcceptApply from "./childCpn/btn/AcceptApply";
  // 承兑应答 - 确认
	import AcceptConfirm from "./childCpn/btn/AcceptConfirm";
  // 承兑应答 - 复核
	import AcceptReview from "./childCpn/btn/AcceptReview";
  // 承兑应答 - 待修改
	import AcceptModify from "./childCpn/btn/AcceptModify";
  // 收票申请 - 待提示收票申请
	import BackBillApply from "./childCpn/btn/BackBillApply";
	// 收票应答 - 待收票应答确认
	import BackBillConfirm from "./childCpn/btn/BackBillConfirm";
  export default {
    name: "TaskDetail",
	  components: {
      CommonOne,
      CommonTwo,
      SpecialOne,
			EndorseApply,
			EndorseAnswer,
			DiscountReview,
			DiscountModify,
			Payment,
			GuaranteeApply,
			PledgeOperate,
			PledgeRecallOperate,
      OutBillReview,
      OutBillModify,
      AcceptApply,
      AcceptConfirm,
      AcceptReview,
      AcceptModify,
      BackBillApply,
      BackBillConfirm
	  },
    mounted(){
      // 获取详情页数据
      this.getbizInfo()
    },
	  data(){
      return {
        activeName: 'first', // 当前tabs激活页
	      searchForm: {
          ticketId: this.$route.query.ticketId, //出票申请id
          handlerTypeCode: this.$route.query.handlerTypeCode, //操作类型code
          handlerNodeCode: this.$route.query.handlerNodeCode  //节点状态code
	      },
        /* 控制展示详情页 -- CP_CHECK：出票查看 -- CP_MODIFY：出票修改 -- BS_APPLY：背书申请 -- BS_RESP：背书应答 -- TX_CHECK：贴现查看 -- TX_MODIFY：贴现修改 
					 FK_CHECK：付款查看 -- BZ_CHECK：保证查看 -- ZY_APPLY：质押申请 -- ZY_RESP：质押应答 -- JC_APPLY：质押解除申请 -- JC_RESP：质押解除应答
				*/
        type: '',
        id: '', // 票据id -- 出票修改
        tradeAmt: '', // 票据包金额
        acceptResp: '', // 承兑应答结果（0：拒绝承兑应答、1：同意承兑应答）
        bizInfo: {}, // 业务信息	-- 公共部分
        logInfoList: [], // 任务流程	-- 公共部分
        otherInfo:{}, // 出票查看 -- 其他信息
        invoiceInfo: {}, // 出票查看 -- 发票信息
        contractInfo: {}, // 出票查看 -- 合同信息
        maker: {}, // 出票查看 - 出票人 -- 后加的
        acceptor: {}, // 出票查看 - 承兑人 -- 后加的
        taker: {}, // 出票查看 - 收票人 -- 后加的
        ticketInfo: {}, // 出票查看 - 票据信息 -- 后加的
        ticketBaseInfoList: [], // 出票查看 - 票据基本信息 -- 后加的
        baseInfo: {}, // 背书申请/背书撤销 -- 背书信息
        superInfoList: [], // 背书申请/背书撤销 -- 母票基本信息
        subInfoList: [], // 背书申请 -- 子票基本信息
        bssOperNo: '', // 背书撤销 - 原背书申请操作编号
        letterApproval: {}, // 信批出票
        discountApply: {}, // 贴现申请
				payResp: '', // 付款查看 -- 付款应答确认结果（0：拒绝应答、1：同意应答）
				baseInfoList: [], // 付款查看 -- 票据基本信息
				payeeInfo: {}, // 付款查看 -- 收款信息
				ensureResp: '', // 保证管理 -- 保证应答结果	(0：拒绝应答、1：同意应答)
				ensureInfo: {}, // 保证管理 -- 保证信息	
				subBaseInfoList: [], // 质押管理 -- 子票基本信息	
				pledgeInfoMore: {}, // 质押管理 -- 质押信息	
				dePledgeInfoMore: {}, // 质押管理 -- 质押解除信息	
      }
	  },
	  methods: {
      // 获取详情页数据
      async getbizInfo(){
        const {data: res} = await this.$auth.get('/cpiaoju-ticket/workbench/task/todo/mt/handle',{params: this.searchForm})
        if(res.code !== 200) return this.$message.error(res.msg);
        this.type = res.data.type;
				const {type} = this
        if (type == 'CP_CHECK' || type == 'CP_MODIFY' || type == 'BS_APPLY' || type == 'BS_RESP' || type == 'FK_CHECK' || type == 'BZ_CHECK' || type == 'ZY_APPLY' || type == 'ZY_RESP' || type == 'JC_APPLY' || type == 'JC_RESP'){
          let {detail: {id, tradeAmt, acceptResp, bizInfo, logInfoList, otherInfo, invoiceInfo, contractInfo, maker, acceptor, taker, ticketInfo, ticketBaseInfoList, baseInfo, superInfoList, subInfoList, bssOperNo, payResp, baseInfoList, payeeInfo, ensureResp, ensureInfo, subBaseInfoList, pledgeInfoMore, dePledgeInfoMore}} = res.data
          this.id = id // 票据id -- 出票修改
          this.tradeAmt = tradeAmt // 票据包金额
          this.acceptResp = acceptResp // 承兑应答结果（0：拒绝承兑应答、1：同意承兑应答）
          this.bizInfo = bizInfo; // 业务信息	-- 公共部分
          this.logInfoList = logInfoList; // 任务流程	-- 公共部分
          this.otherInfo = otherInfo; // 出票查看 -- 其他信息
          this.invoiceInfo = invoiceInfo; // 出票查看 -- 发票信息
          this.contractInfo = contractInfo; // 出票查看 -- 合同信息
          // ------- 一期出票后加的，展示出票人、承兑人、收票人、票据信息，或者票据基本信息
          this.maker = maker
          this.acceptor = acceptor
          this.taker = taker
          this.ticketInfo = ticketInfo
          this.ticketBaseInfoList = ticketBaseInfoList
          // ------- 背书申请 --------
          this.baseInfo = baseInfo; // 背书申请 -- 背书信息
          this.superInfoList = superInfoList; // 背书申请 -- 母票基本信息
          this.subInfoList = subInfoList;// 背书申请 -- 子票基本信息
          // -------- 背书撤销 ---------
          this.bssOperNo = bssOperNo
					// -------- 付款查看 ---------
					this.payResp = payResp // 付款查看 -- 付款应答确认结果（0：拒绝应答、1：同意应答）
          this.baseInfoList = baseInfoList // 付款查看 -- 票据基本信息
					this.payeeInfo = payeeInfo // 付款查看 -- 收款信息
					// -------- 保证管理 ----------
					this.ensureResp = ensureResp
					this.ensureInfo = ensureInfo
					// -------- 质押管理 ----------
					this.subBaseInfoList = subBaseInfoList
					this.pledgeInfoMore = pledgeInfoMore
					this.dePledgeInfoMore = dePledgeInfoMore
        }else if (res.data.type === 'TX_CHECK' || res.data.type === 'TX_MODIFY'){
          this.bizInfo = res.data.detail
          this.discountApply = res.data.detail // 贴现申请 --- 二期
          this.logInfoList = res.data.logInfoList
        }
      }
	  },
	  // computed: {
    //   isShow(){
    //     if (this.bizInfo.handlerTypeCode === 10100000 && this.bizInfo.handlerNodeCode === 10101060){
    //       return true
    //     }else {
    //       return false
    //     }
    //
    //   }
	  // }
  }
</script>

<style lang="less" scoped>
	.el-tabs{
		margin-top: 30px;
	}
	.btn{
		/deep/ .btn-group{
			text-align: center;
		}
		margin: 30px 0;
		/deep/ .el-button{
			min-width: 110px;
			margin-right: 20px !important;
		}
		/deep/ .el-textarea__inner{
			min-height: 80px !important;
		}
		/*同意收票弹框*/
		/deep/ .agree-dialog{
			display: flex;
			flex-direction: column;
			align-items: center;
			h3{
				margin-top: 20px;
			}
			.copy-code{
				margin-top: 15px;
				h4{
					display: inline-block;
					color: #C70009;
				}
			}
			.el-button{
				margin: 30px 24px 0;
			}
		}
	}
	/deep/ .verify-code{
		width: 220px;
	}
</style>